import React from 'react';
import DeliveryHoursCalc from './DeliveryHoursCalc';
import DeliveryTimeDayTypes from './DeliveryTimeDayTypes';
import { useSelector } from "react-redux";

const DeliveryTimeEdit = () => {

    const { deliveryPrice, minOrder } = useSelector((state) => ({
        deliveryPrice: state.productsInCart.deliveryPrice,
        minOrder: state.productsInCart.minOrder
    }));
    

    return (
        <div className="delivery-time-edit">
            <div className='title-role'>
                <h2>דמי משלוח: {deliveryPrice} ₪ | מינימום הזמנה: {minOrder} ₪</h2>
                <h1>עריכת זמני משלוח:</h1>
            </div>
            <DeliveryHoursCalc/>
            <DeliveryTimeDayTypes/>
        </div>
    )
}

export default DeliveryTimeEdit 
import * as actionType from './action'

const initialState = {
    user: null,
    role: null,
    token: null,
    isValidToken: true,
    userEntry: [true, "login"],
    stationId: null,
    userHaveReset: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.LOGIN:
            return {
                ...state,
                ...action.payload,
                userEntry: [false, "login"],
            };
        case actionType.LOGOUT:
            return {
                ...state,

                user: null,
                role: null,
                userEntry:  [true, "login"],

            };
        case actionType.SET_IS_VALID_TOKEN:
            return {
                ...state,
                isValidToken: action.payload
            };
        case actionType.UPDATE_STATION_ID:
            return {
                ...state,
                stationId: action.payload,
            };
        case actionType.USER_ENTRY:
            return {
                ...state,

                userEntry: action.payload,
            };
        case actionType.VERIFIED_EMAIL:
            return {
                ...state,
                userHaveReset: action.payload,
            };
        default:
            return state
    }
}

export default reducer; 
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector  } from 'react-redux';
import { CollectSiteProductSetProduct } from '../../../server/CollectSite';
import { setUserEntry } from '../../../server/Customer'
import { ShoppingCart } from "lucide-react";
import { GetSalePrice } from "../../../server/SalesManagement";

const ProductAddToCart = ({ element, haveInfo }) => {
  const dispatch = useDispatch();
  const { user, role, productsInCart, stationId } = useSelector(state => ({
    role: state.user.role,
    user: state.user.user,
    productsInCart: state.productsInCart.productsInCart,
    stationId: state.user.stationId,

  }) );
  const [salePrice, setSalePrice] = useState(null);

  useEffect(() => {
    if (element?.Mkt) {
      GetSalePrice(element.Mkt, stationId)
        .then((res) => {
          if (res.data && res.data !== -1 && res.data !== element.Price) {
            setSalePrice(parseFloat(res.data).toFixed(2));
          }
        })
        .catch((err) => console.error("Error fetching sale price:", err));
    }
  }, [element?.Mkt, stationId]);

  const inCart = productsInCart.find(item => item.ProductMkt === element.Mkt || item.ProductMkt === element.ProductMkt);

  const CollectSiteProductSetProducts = (element, quantity ,stationId) => {
    if (!user) {
      dispatch(setUserEntry([true, "signIn"]))
    }
    else {
      const finalPrice = salePrice || element.Price; // אם יש מחיר מבצע, נשתמש בו
      dispatch(CollectSiteProductSetProduct({ ...element, Price: finalPrice }, quantity, stationId));
    }
  }

  const WeightJump = element.WeightJump > 0 ? element.WeightJump : 1;

  return (
    <div className={inCart ? "add-to-cart in-cart" : "add-to-cart not-in-cart"}>

      {/* <div className="product-page barcode-pop"> */}
      {!role && (element.QuantityCanOrder >= WeightJump || (inCart && inCart.Quantity > 0)) ?
        // <div className="a" onClick={!inCart ? () => CollectSiteProductSetProducts(element, 1) : null}>
        <div className="wrap flex-container" onClick={!inCart ? () => CollectSiteProductSetProducts(element, 1,stationId) : null}>
          {inCart && inCart.Quantity > 0 ?
            <>
              <div className="add-btn" onClick={inCart && (!haveInfo || element.QuantityCanOrder >= WeightJump) ? () => CollectSiteProductSetProducts(element, 1, stationId) : null}>
                {/* <img  loading="lazy" src={globalFileIcon + 'plus-clean.svg'} alt="add" /> */}
                <p>+</p>
              </div>
              <div className="col-lg-7">
                <input readOnly
                  type="text"
                  value={inCart.Quantity}
                  disabled={true}
                />
              </div>
              <div className="minus-btn" onClick={inCart ? () => CollectSiteProductSetProducts(element, -1, stationId) : null}>
                {/* <img  loading="lazy" src={globalFileIcon + 'cart_minus.svg'} alt="minus" /> */}
                <p>-</p>
              </div>
            </> :
            <p onClick={inCart && (!haveInfo || element.QuantityCanOrder >= WeightJump) ? () => CollectSiteProductSetProducts(element, 1, stationId) : null}>
              <ShoppingCart size={24}/>
            </p>
          }
        </div>
        : (inCart && inCart?.Quantity == 0) && (
            <div className="outOfStock">אזל מהמלאי</div>
          )
        }
    </div>
  )
}



export default ProductAddToCart;
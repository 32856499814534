import React, { useEffect, useState } from 'react';
import { getInventoriesPerMktOrBarcode, getSaveLocations } from '../../../server/InventoryUpdate'
import showAlert from '../../../server/ShowAlert'
import { useDispatch, useSelector } from 'react-redux'
import { setPreload } from '../../../server/CartsOpen';
import InventoryTable from './InventoryTyble';
import InventoryInfo from './InventoryInfo';
import InventoryButton from './InventoryButton';
import { useParams } from 'react-router-dom';

const InventoryUpdate = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [inputMkt, setInputMkt] = useState("");
    const [inputBarcode, setInputBarcode] = useState("");
    const [inputMktOrBarcode, setInputMktOrBarcode] = useState(id);
    const [inputAvailableInventory, setInputAvailableInventory] = useState("")
    const [inputGeneralInventory, setInputGeneralInventory] = useState("")
    const [inventory, setInventory] = useState([])
    const [showUnavailableInventory, setShowUnavailableInventory] = useState(false);
    const [saveLocations, setSaveLocations] = useState([]);
    const stationId = useSelector(state => state.user.stationId)


    useEffect(() => {
        GetSaveLocations();
        if (id) {
            setInputMktOrBarcode(id)
            SearchProd();
        }
    }, [])

    useEffect(() => {
        Cancel(false);
    }, [stationId]);
    
    const Cancel = (ifSerchNext = true) => {
        const buttons = [...saveLocations];
        buttons.forEach(x => {
            x.clicked = false
        })
        setShowUnavailableInventory(false)
        setInventory([])
        setInputMkt("")
        setInputMktOrBarcode("")
        setSaveLocations(buttons)
        if (id) {
            setInputMktOrBarcode(id)
            if (ifSerchNext) {
                SearchProd()
            }
        }
    }



    const SearchProd = () => {
        dispatch(setPreload(true));
        let mkt = inputMktOrBarcode.length === 4 ? inputMktOrBarcode : null;
        let barcode = inputMktOrBarcode.length > 4 ? inputMktOrBarcode : null;
        if (!mkt && id) {
            mkt = id.length === 4 ? id : null;
        }
        Cancel(false)
        if (mkt || barcode) {
            getInventoriesPerMktOrBarcode(mkt, barcode, true, stationId)
                .then(x => {
                    if (x.data.length) {
                        setInventory(x.data);
                        setInputMktOrBarcode(inputMktOrBarcode.length > 4 ? x.data[0].Barcode : x.data[0].ProductMkt)
                        setInputMkt(x.data[0].ProductMkt);
                        setInputBarcode(x.data[0].Barcode)
                        setInputAvailableInventory(x.data.filter(x => x.StokeTypeId === 1).reduce((total, x) => total = total + x.Quantity, 0))
                        setInputGeneralInventory(x.data.reduce((total, current) => total += current.Quantity, 0))
                    }
                    else {
                        showAlert({ msgTitle: "לא נמצא מלאי מתאים", msgText: "בדוק אם הברקוד או המק״ט תקין ", msgType: "error" })
                    }
                })
                .catch(() => showAlert())
                .finally(() => dispatch(setPreload(false)));
        }
    }

    const GetSaveLocations = () => {
        getSaveLocations(stationId)
            .then(x => setSaveLocations(x.data))
            .catch(() => console.log("error"));
    }

    return (
        <div className="inventory-update">
            {!id ? <h1 className='title-role'>עדכון מלאי</h1> : null}
            <div className="container main-container">
                <div className="flex-container">
                    <div className='prod-details'>
                        <InventoryInfo
                            SearchProd={SearchProd}
                            inputAvailableInventory={inputAvailableInventory}
                            inputGeneralInventory={inputGeneralInventory}
                            ProductFullDescription={inventory.length ? inventory[0]?.ProductFullDescription : null}
                            inputMkt={inputMkt}
                            inputBarcode={inputBarcode}
                            inputMktOrBarcode={inputMktOrBarcode}
                            setInputMktOrBarcode={(x) => setInputMktOrBarcode(x)}
                            setInputMkt={(x) => setInputMkt(x)}
                            setShowUnavailableInventory={(x) => setShowUnavailableInventory(x)}
                            showUnavailableInventory={showUnavailableInventory}
                        />
                        <InventoryTable
                            inventory={inventory}
                            Cancel={() => Cancel(true)}
                            showUnavailableInventory={showUnavailableInventory}
                            SearchProd={SearchProd}
                            setInventory={(x) => setInventory(x)}
                        />
                    </div>
                    <div className='option-details'>
                        <InventoryButton
                            inputMkt={inputMkt}
                            inventory={inventory}
                            saveLocations={saveLocations}
                            setInventory={x => setInventory(x)} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InventoryUpdate 
import axios from "axios";
import querystring from "querystring";
import { url, awsUrl } from "../../axiosInstance";
import * as actionType from "../store/action";
import showAlert from "./ShowAlert";
import { setPreload } from "./CartsOpen";
import { GetStationsToUser } from "./Station";

export const LogOut = () => {
  return (dispatch) => {
    localStorage.removeItem("token");
    localStorage.removeItem("jwtToken");
    dispatch({ type: actionType.LOGOUT });
  };
};

export const loginPost = (isUser, password) => {
  return (dispatch) => {
    axios
      .get(`${url}Login/GetUser`, {
        headers: {
          Authorization: `bearer ${localStorage.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((x) => {
        GetStationsToUser(x.data?.Id).then((x) => {
          dispatch(updateStationId(x.data[0]?.Id));
        });
        if (x.data.UserTypeId !== 5)
          if (x.data.IsEmailVerified || x.data.UserTypeId) {
            dispatch({
              type: actionType.LOGIN,
              payload: {
                user: x.data,
                role: x.data.UserTypeId < 4,
                stationId: x.data.StationId,
              },
            });
          } else {
            localStorage.removeItem("token");
            localStorage.removeItem("jwtToken");
            dispatch({
              type: actionType.VERIFIED_EMAIL,
              payload: {
                customerId: x.data.Id,
                Email: x.data.Phone,
                Password: password,
                passwordMail: x.data.passwordMail,
              },
            });
          }
      })

      .catch(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("jwtToken");
        if (isUser) {
          showAlert({ msgTitle: "שם משתמש וסיסמה שגויים", msgType: "error" });
        }
      });
  };
};
export const PutCustomer = (data) => {
  return (dispatch) => {
    axios
      .post(`${url}Customer/PutCustomer`, data, {
        headers: {
          Authorization: `bearer ${localStorage.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((x) => {
        dispatch({
          type: actionType.LOGIN,
          payload: {
            user: x.data,
            role: false,
            stationId: x.data.StationId,
          },
        });
      })
      .finally((c) => dispatch(setPreload(false)));
  };
};

export const UpdateCustomerStationId = (customerId, stationId) => {
  return (dispatch) => {
    axios
      .post(
        `${url}customer/station?Id=${customerId}&StationId=${stationId}`,
        null,
        {
          headers: {
            Authorization: `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionType.UPDATE_STATION_ID,
          payload: stationId,
        });
      })
      .catch((error) => {
        console.error("Error updating Station ID:", error);
      })
      .finally(() => dispatch(setPreload(false)));
  };
};

export const sendVerifiedMail = (customerId) => {
  return axios.get(
    `${url}Login/setCustomerVerifeidMail?CustomerId=${customerId}`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
};

export const loginToken = (data, isUser) => {
  return async (dispatch) => {
    try {
      // Send both requests concurrently and wait for both to succeed
      const [awsResponse, mainResponse] = await Promise.all([
        axios.post(`${awsUrl}/v1/login`, {
          username: data.userName,
          password: data.password,
        }),
        axios.post(`${url}login`, querystring.stringify(data), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }),
      ]);

      // Store tokens only if both requests are successful
      localStorage.setItem("jwtToken", awsResponse.data.access_token);
      localStorage.setItem("token", mainResponse.data.access_token);

      // Dispatch action only after both requests succeed
      dispatch(loginPost(isUser, data.password));
    } catch (error) {
      // Show error alert if either request fails
      const errorMessage =
        error.response?.status === 400 ? "שם משתמש וסיסמה שגויים" : "שגיאה";
      showAlert({ msgTitle: errorMessage, msgType: "error" });
    }
  };
};

export const customerRegister = (val) => {
  return (dispatch) => {
    axios
      .post(`${url}customer`, val)
      .then((x) => {
        dispatch({
          type: actionType.VERIFIED_EMAIL,
          payload: {
            customerId: x.data.Id,
            Email: val.Email,
            Password: val.password,
            passwordMail: x.data.Name,
          },
        });
      })
      .catch((err) => {
        const errMsg = "לא הצלחנו להקים את הלקוח נסה במועד מאוחר יותר";
        showAlert({
          msgTitle: err.response.data.Message
            ? err.response.data.Message
            : errMsg,
        });
      })
      .finally(() => dispatch(setPreload(false)));
  };
};

export const sendPassword = (email) => {
  return (dispatch) => {
    axios
      .get(`${url}Customer?Email=${email}`)
      .then((x) => {
        showAlert({ msgTitle: "נשלח לך מייל", msgType: "success" });
      })
      .catch((err) =>
        showAlert({
          msgTitle: "לא מצאנו את כתובת המייל במערכת",
          msgType: "error",
        })
      )
      .finally(() => dispatch(setPreload(false)));
  };
};

export const ChangPassword = (data) => {
  return (dispatch) => {
    dispatch(setPreload(true));
    axios
      .post(`${url}ChangPassword`, data, {
        headers: {
          Authorization: `bearer ${localStorage.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((x) => {
        if (x.data) {
          showAlert({ msgTitle: "הסיסמה עודכנה בהצלחה", msgType: "success" });
        } else {
          throw new Error();
        }
      })
      .catch((err) =>
        showAlert({ msgTitle: "קוד האיפוס אינו תקין", msgType: "error" })
      )
      .finally(() => dispatch(setPreload(false)));
  };
};

export const setUserEntry = (value) => {
  return (dispatch) =>
    dispatch({ type: actionType.USER_ENTRY, payload: value });
};

export const updateStationId = (value) => {
  return (dispatch) =>
    dispatch({ type: actionType.UPDATE_STATION_ID, payload: value });
};

export const getAllCustomer = (stationId) => {
  return axios.get(`${url}Customer?stationId=${stationId}`, {
    headers: {
      Authorization: `bearer ${localStorage.token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
};

import React, { Fragment, useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import { setUserEntry, LogOut, updateStationId } from "../../server/Customer";
import {  globalFileIcon } from '../../../axiosInstance';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";
import Accessibility from '../Accessibility'
import CustomerService from '../customerService/CustomerService';
import PrivateAreaList from '../privateArea/PrivateAreaList';
import { setToggleCart } from "../../server/CartsOpen";
import { FiArrowRight } from 'react-icons/fi';
import { GetStation, GetStationsToUser } from "../../server/Station";

const HeaderConnection = ({ setAdminToCustomer, user, stationId, role, setAllProduct}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = useState(false);
    const [openPrivateAreaList, setOpenPrivateAreaList] = useState(false);
    const [userStation, setUserStation] = useState(null);
    const [adminStation, setAdminStation] = useState([]);
    const selectStation = useSelector(state => state.user.stationId);
    
    const handleNavigation = () => {
        dispatch(setToggleCart(false));
        navigate('/about');
    };

    const exit = () => {
        navigate('/');
        dispatch(LogOut())
    }

    const check = (stationId) => {    
        const newStationId = stationId;
        dispatch(updateStationId(newStationId));
        if(user){
             user.StationId = newStationId;
        }
    }   
    
    useEffect(() => {
        if(role){
            GetStationsToUser(user?.Id).then((x) => {
                setAdminStation(x.data);
            });
        }
        else{
            GetStation(selectStation).then((x) => setUserStation(x.data));
        }
    }, [user, stationId, selectStation])


    const changeProductDisplay = (value) => {
        switch(value){
            case "allProduct":
                setAdminToCustomer(true);
                setAllProduct(true);
                break;
            case "active":
                setAdminToCustomer(true);
                setAllProduct(false);
                break;
            case "customer":
                setAdminToCustomer(false);
                setAllProduct(false);
                break;
            default:
                console.warn("ערך לא מוכר:", value);
        }       
    }
    
    return (
        <div className='header-connection'>
            <div className="back-and-logo">
                <button className="back-button" onClick={()=>navigate(-1)}>
                    <FiArrowRight />
                </button>
                <NavLink to="/" className='site-icon'>
                    <img loading="lazy" src={globalFileIcon + 'site-icon.svg'} alt="site" />
                </NavLink>
            </div>
            <div className='header-data'>
                <li className="data" onClick={handleNavigation}>
                    <img loading="lazy" src={globalFileIcon + 'about.svg'} alt="about" />
                   <span className="about">אודות</span>
                </li>
                <li className={openMenu? "data open-menu":"data" } onClick={() => setOpenMenu(true)} >
                    <img  loading="lazy" src={globalFileIcon + 'customer-service.svg'} alt="customer-service" />
                    <a className='customer-service'>שירות לקוחות</a>
                </li>
                {openMenu ? <div className='customer-service-menu'><CustomerService setOpenMenu={setOpenMenu} /></div> : null}
                <Accessibility />
            </div>
            <div className="login-or-sign-in">
                {!user ? (
                    <div>
                        <button className="login" onClick={() => dispatch(setUserEntry([true, "login"]))}>
                            כניסה
                        </button>
                        <button className="sign-in" onClick={() => dispatch(setUserEntry([true, "signIn"]))}>
                            הרשמה
                        </button>
                    </div>
                ) : (
                    <Fragment>
                        <div> {role &&
                            <button >
                                <select className="custom-select" onChange={(e) => changeProductDisplay(e.target.value)}>
                                    <option value="allProduct">תצוגת כל המוצרים</option>
                                    <option value="active">תצוגת פעילים</option>
                                    <option value="customer">תצוגת לקוח</option>
                                </select>
                            </button>} 
                            {role ? (
                                <button className="btn-to-private-area station-user">
                                        מנהל /{" "}
                                    <select onChange={(e) => check(e.target.value)} className="custom-select">
                                        {adminStation.map((station) => (
                                            <option key={station.Id} value={station.Id}>
                                                {station.Name}
                                            </option>
                                        ))}
                                    </select>
                                </button>
                            ) : (
                                <button className="btn-to-private-area station-user">
                                    <span className="custom-customer">תחנת{" "}{userStation?.Name}</span>
                                </button>
                            )}
                            {!user.UserTypeId ? (
                                <Fragment>
                                    <button onClick={() => setOpenPrivateAreaList(true)} className="btn-to-private-area">
                                        <span>לאזור האישי של {user.Name} {user.LastName}</span>
                                    </button>
                                    {openPrivateAreaList ? (
                                        <div className='private-area-list-menu'>
                                            <PrivateAreaList setOpenPrivateAreaList={setOpenPrivateAreaList} />
                                        </div>
                                    ) : null}
                                </Fragment>
                            ) : null}

                            <button onClick={exit} className="exit">
                                <span>יציאה</span>
                            </button>
                        </div>   
                    </Fragment>
                )}
            </div>

        </div>
    )
}

export default HeaderConnection

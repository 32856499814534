import React, { useContext } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { globalFileIcon } from '../../../axiosInstance';
import './SubNavbar.scss';

const SubNavbar = ({ fatherId, currentSubCategory, level=1 }) => {
  const { categories } = useSelector(state => ({
    categories: state.category.categories,
  }));

  const subCategories = categories.filter(category => category.FatherId === fatherId);
  const { pathname } = useLocation();

  const getPathToLink = (pathname, toSearch) => {
    const splitPath = pathname.split('/');
    const indexOf = splitPath.indexOf(toSearch);
    return indexOf === -1 ? pathname : splitPath.slice(0, indexOf).join('/');
  }

  if(subCategories.length === 0)
    return null;
  return (
    <>
      <nav className={`sub-navbar level-${level}`}>
        <ScrollMenu 
          LeftArrow={LeftArrow} 
          RightArrow={RightArrow} 
          RTL
          wrapperClassName="custom-scroll-wrapper"
          scrollContainerClassName="custom-scroll-container"
        >
          {subCategories.map((category) =>
            category.Active &&
              <NavLink
                key={category.Id}
                to={`${getPathToLink(pathname, fatherId.toString())}/${fatherId}/${category.Id}`}
                className={`sub-navbar-item ${category.Id === currentSubCategory ? 'active' : ''}`}>
                {category.Name}
              </NavLink>
          )}
        </ScrollMenu>
      </nav>
      {pathname.split("/")[level+2]  && <SubNavbar fatherId={parseInt(pathname.split("/")[level+2])} currentSubCategory={currentSubCategory} level={level+1}/>}
    </>
  );
};


const ArrowButton = ({ children, disabled, onClick, className }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`custom-arrow-btn ${className}`}
    >
      {children}
    </button>
  );
};

const LeftArrow = () => {
  const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);
  return (
    <ArrowButton disabled={isLastItemVisible} onClick={() => scrollNext()} className="left-arrow">
      <img src={globalFileIcon + 'open-cart.svg'} alt="scroll left" className="rotate-90" />
    </ArrowButton>
  );
};

const RightArrow = () => {
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);
  return (
    <ArrowButton disabled={isFirstItemVisible} onClick={() => scrollPrev()} className="right-arrow">
      <img src={globalFileIcon + 'open-cart.svg'} alt="scroll right" className="rotate-270" />
    </ArrowButton>
  );
};

export default SubNavbar;
import React, { useEffect, useState } from "react";
import { globalFileServer } from "../../../../axiosInstance";
import ProductInfo from "../../productInfo/ProductInfo";
import { useSelector } from "react-redux";
import { GetSalePrice } from "../../../server/SalesManagement";

const Detailes = ({
  element,
  inCart,
  productPopUp,
  comparePricesPopUp,
  srcPrev,
}) => {
  const { stationId, role } = useSelector((state) => ({
    role: state.user.role,
    stationId: state.user.stationId,
  }));

  const [salePrice, setSalePrice] = useState(null);

  useEffect(() => {
    if (element?.Mkt) {
      GetSalePrice(element.Mkt, stationId)
        .then((res) => {
          if (res.data && res.data !== -1 && res.data!=element.Price) {
            setSalePrice(parseFloat(res.data).toFixed(2));
          }
        })
        .catch((err) => console.error("Error fetching sale price:", err));
    }
  }, [element?.Mkt]);

  
  return (
    <>
      <div className="item-card-details" onClick={productPopUp}>
        <div
          className={inCart ? "card-img-in-card" : "card-img"}
          style={srcPrev ? { width: "190px" } : { width: "none" }}
        >
          <img
            className={"img"}
            loading="lazy"
            src={
              srcPrev != null
                ? srcPrev
                : element.PathUrl
                ? globalFileServer +
                  ("products/" +
                    element.PathUrl.split(",")[0] +
                    "?width=150&height=150")
                : globalFileServer + "placeholder.jpg"
            }
            alt={element.ProductName}
          />
        </div>
        <div className="desc">
          <span className="product-info">
            <ProductInfo selectedProd={element} />
          </span>
          <div className="kosher-and-price">
          {salePrice && <span className="current-price">{salePrice}</span>}
            <div className="price-main-cont">
              {element.Price && element.Price !== "0" ? (
                <span
                  className={salePrice ? "price with-sale" : "current-price"}
                  onClick={(e) => {
                    e.stopPropagation();
                    comparePricesPopUp();
                  }}
                  data-tip
                  data-for="registerTipComparePrice"
                >
                  {parseFloat(element.Price).toFixed(2)}
                </span>
              ) : null}
            </div>
            {element.Kashrut ? (
              <div className="prod-info-kosher">
                {element.KashrutId !== "19" ? (
                  <>
                    <img
                      loading="lazy"
                      src={
                        globalFileServer +
                        "iconcss/" +
                        element.KashrutId +
                        ".svg"
                      }
                      alt={element.Kashrut}
                    />
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Detailes;

import axios from 'axios';
import { url } from '../../axiosInstance';




export const UploadImage = (data) => {
    return axios.post(`${url}UploedImage`, data,{
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const getImgAdmin = () => {
    return axios.get(`${url}ImageForAdmin`,{
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const UploadImageData = (data) => {
    const formData = new FormData();

    let imageToUpload = data.selectedFile;
    if (data.selectedFile && typeof data.selectedFile === "string" && data.selectedFile.startsWith("data:image")) {
        const dataUrlToBlob = (dataUrl) => {
            const byteString = atob(dataUrl.split(",")[1]);
            const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];
            const arrayBuffer = new Uint8Array(byteString.length);
            for (let i = 0; i < byteString.length; i++) {
                arrayBuffer[i] = byteString.charCodeAt(i);
            }
            return new Blob([arrayBuffer], { type: mimeString });
        };
        imageToUpload = dataUrlToBlob(data.selectedFile); 
    }

    formData.append("Image", imageToUpload); 
    formData.append("FileName", data.FileName); 
    formData.append("Mkt", data.Mkt); 
    formData.append("ItemId", data.ItemId); 

    if (data.IdImgGs1) {
        formData.append("IdImgGs1", data.IdImgGs1); 
    }
    if (data.SourceImgId) {
        formData.append("SourceImgId", data.SourceImgId); 
    }


    return axios.post(`${url}Product/UploadImageToServer`, formData, {
        headers: {
            'Authorization': `Bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*",
        },
    });
};

export const deleteImageFromServer = (data) => {
    const formData = new FormData();
    formData.append("Mkt", data.Mkt);
    formData.append("ItemId", data.ItemId);
    formData.append("FileName", data.FileName);

    return axios.post(`${url}Product/DeleteImageFromServer`, formData, {
        headers: {
            'Authorization': `Bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*",
        },
    });
};


import axios from 'axios';
import { url } from '../../axiosInstance';
import * as actionType from '../store/action'

export const PriceDeliveryState = (lat, lng, polygons) => dispatch => {
    const idPrice = PriceDelivery(lat, lng, polygons);
    const price = idPrice.Price;
    dispatch({ type: actionType.SET_DELIVERY_PRICE, payload: price })
}

export const PriceDelivery = (lat, lng, polygons) => {
    const google = window.google;
    const coord = new google.maps.LatLng(lat, lng);
    const neib = [];
    polygons.forEach(item => {
        const newPolygon = new google.maps.Polygon({ paths: item.polygon_point });
        if (google.maps.geometry.poly.containsLocation(coord, newPolygon)) {
            neib.push({ Price: item.Price, PolygonStationId: item.StationId });
        }
    })
    if (neib.length) {
        const minPriceObj = neib.reduce((min, current) => current.Price < min.Price ? current : min);
        return minPriceObj;
    }
    return null;
}

export const polygon = (StationId) => {
    return axios.get(`${url}polygon?StationId=${StationId}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const allPolygon = () => {
    return axios.get(`${url}polygon/all`, {
    })
}

export const polygonAdd = (data) => {
    return axios.post(`${url}polygon`, data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const polygonStation = (data) => {
    return axios.post(`${url}stationDelivery`, data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}
export const deletePolygon = (data) => {

    return axios.post(`${url}polygon/delete`, data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}
export const deletePoint = (data) => {

    return axios.post(`${url}polygonPoint/delete`, data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const polygonPointAdd = (data) => {
    return axios.post(`${url}polygonPoint`, data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

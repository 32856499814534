import React, { Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { globalFileIcon } from "../../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import {
  setToggleMenu,
  setToggleCart,
  setToggleSidebar,
} from "../../server/CartsOpen";
import Navigator from "./NAVIGATOR";
import HeaderConnection from "./HeaderConnection";
import HeaderSearch from "./HeaderSearch";
import HeaderFilterAndSort from "./HeaderFilterAndSort";
import HeaderCart from "./HeaderCart";

const Header = ({ setAllProduct, setAdminToCustomer, currentSubCategory }) => {
  const dispatch = useDispatch();
  const {
    user,
    role,
    stationId,
    productsInCart,
    toggleMenu,
    toggleSidebar,
    deliveryPrice,
  } = useSelector((state) => ({
    user: state.user.user,
    role: state.user.role,
    stationId: state.user.stationId,
    productsInCart: state.productsInCart.productsInCart,
    toggleMenu: state.openCart.toggleMenu,
    toggleSidebar: state.openCart.toggleSidebar,
    deliveryPrice: state.productsInCart.deliveryPrice,
  }));

  const { pathname } = useLocation();

  useEffect(() => {
    if (!role && user) {
      dispatch(setToggleCart(true));
    }
  }, []);

  return (
    <header>
      <div className="header-wrapper">
        <HeaderConnection
          setAllProduct={setAllProduct}
          setAdminToCustomer={setAdminToCustomer}
          user={user}
          role={role}
          stationId={stationId}
        />
        {pathname !== "/about" ? (
          <>
            <div className=" head-right-side">
              <div className="menu" onClick={() => dispatch(setToggleMenu())}>
                {toggleMenu ? (
                  <img src={globalFileIcon + "close-menu.svg"} alt="close" />
                ) : (
                  <img src={globalFileIcon + "menu-new.svg"} alt="menu" />
                )}
              </div>
              <Navigator currentSubCategory={currentSubCategory} />
            </div>
            <div className="header-end">
              {role ? (
                <div
                  onClick={() => dispatch(setToggleSidebar(!toggleSidebar))}
                  className="toggle-nav"
                >
                  {toggleSidebar ? (
                    <img src={globalFileIcon + "close.svg"} alt="menu" />
                  ) : (
                    <img src={globalFileIcon + "menu_new.svg"} alt="menu" />
                  )}
                  <span className="cross-line">|</span>
                </div>
              ) : null}
              <HeaderFilterAndSort />
              <span className="cross-line">|</span>
              <HeaderSearch />
              <HeaderCart
                role={role}
                user={user}
                pathname={pathname}
                productsInCart={productsInCart}
                deliveryPrice={deliveryPrice}
              />
            </div>
          </>
        ) : null}
      </div>
    </header>
  );
};

export default Header;

import axios from 'axios';
import { url } from '../../axiosInstance';

export const GetMosadDetails = () => {
    return axios.get(`${url}Payment/GetMosadDetails`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const Payment = (data) => {
    const headers = {
        'Authorization': `bearer ${localStorage.token}`,
        "Access-Control-Allow-Origin": "*"
    }
    return axios.post(`${url}Payment/TranzilaPayCard`, data, { headers })
}

export const SavePaymentNedarim = (data) => {
    const headers = {
        'Authorization': `bearer ${localStorage.token}`,
        "Access-Control-Allow-Origin": "*"
    }
    return axios.post(`${url}Payment/SavePaymentNedarim`, data, { headers })
}

export const SavePaymen = (data) => {
    const headers = {
        'Authorization': `bearer ${localStorage.token}`,
        "Access-Control-Allow-Origin": "*"
    }
    return axios.post(`${url}Payment/SavePaymen`, data, { headers })
}

export const GetPaymentPerOrder = (orderId) => {
    return axios.get(`${url}Payment/GetPayment?OrderId=${orderId}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}
export const GetCards = () => {
    return axios.get(`${url}Tranzila/cards`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}


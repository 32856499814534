import React, { Fragment, useEffect, useState } from 'react';
import MyEditor from "./tools/MyEditor";
import { globalFileIcon } from '../../axiosInstance';
import { OpenTextGet, OpenTextSet } from '../server/OpenText';
import { setPreload } from '../server/CartsOpen';
import TimeYearMonthDate from './containers/Date'
import DOMPurify from 'dompurify';
import { useSelector, useDispatch } from 'react-redux';



const OpenText = ({ShowHome}) => {
    const dispatch = useDispatch();
    const [homeObj, setHomeObj] = useState([]);
    const [editId, setEditId] = useState(null);
    const role = useSelector(state => state.user.role);

    useEffect(() => {
        OpenTextGet()
            .then(x => setHomeObj(x.data))
            .catch(() => console.log("error"))
    }, [role])

    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    const convertContentToHTML = (value, type, Id) => {
        const obj = [...homeObj];
        const index = obj.findIndex(x => x.Id === Id);
        obj[index][type] = value;
        setHomeObj(obj);
    }

    const setNew = () => {
        const obj = [...homeObj];
        obj.push({ Id: -1 });
        setHomeObj(obj);
        setEditId(-1)
    }

    const save = (Id) => {
        dispatch(setPreload(true));
        const obj = [...homeObj];
        const index = obj.findIndex(x => x.Id === Id);

        OpenTextSet(obj[index])
            .then(x => {
                obj[index] = x.data;
                setHomeObj(obj);
                setEditId(null)
            })
            .catch(() => console.log("error"))
            .finally(() => dispatch(setPreload(false)));

    }
    return (
        <section className="system-message">
            <p className='header-system-text'>כדאי שתדעו!</p>
            <span className='system-body'>
                <div className="system-edit-text">
                    {homeObj.map(x =>
                        ShowHome && role && editId === x.Id ?
                            <MyEditor key={x.Id} homeObj={x}
                                save={save}
                                convertContentToHTML={convertContentToHTML} />
                            :
                            new Date(x.ToDate) >= new Date() || (role && ShowHome) ?
                                <Fragment key={x.Id}>
                                    <div className='system-text' dangerouslySetInnerHTML={createMarkup(x.Text)}></div>
                                    {role && ShowHome ?
                                        <Fragment>
                                            <div>{x.FromDate.split("T")[0]}</div>
                                            <div>{x.ToDate.split("T")[0]}</div>

                                            <button className='btn' onClick={() => setEditId(x.Id)}>לעריכה</button>
                                        </Fragment> : null}
                                </Fragment> : null
                    )
                    }
                    {role && ShowHome && !homeObj.findIndex(x => x.Id === -1) >= 0 ? <button className='btn' onClick={setNew}>הוספת חדש</button> : null}
                </div>
            </span>
        </section>
    )
}

export default OpenText

import React, { useState, useEffect } from 'react';
import { GetSalesList, DeleteSale } from '../../../server/SalesManagement';
import { setPreload } from '../../../server/CartsOpen';
import showAlert from '../../../server/ShowAlert';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import SalePage from './SalePage';
import { globalFileServer, globalFileIcon } from '../../../../axiosInstance';
import { showAlertWithConfirmCancel } from '../../../server/ShowAlert';
import { useNavigate } from 'react-router-dom';

const SalesManagement = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [salesList, setSalesList] = useState([]);
    const [partialSalesList, setPartialSalesList] = useState([]);
    const stationId = useSelector((state) => state.user.stationId);

    useEffect(() => {
        getSalesList();
    }, [stationId])

    const getSalesList = () => {
        dispatch(setPreload(true));
        GetSalesList(stationId)
            .then(x => {
                if (x.data) {
                    setSalesList(x.data)
                }
            })
        .catch(() => showAlert({}))
        .finally(() => dispatch(setPreload(false)));
        GetSalesList()
            .then(x => {
                if (x.data) {
                    setPartialSalesList(x.data)
                }
            })
        .catch(() => showAlert({}))
        .finally(() => dispatch(setPreload(false)));
    }

    const handleDeleteSale = (saleId) => {
        showAlertWithConfirmCancel({
            msgTitle: 'האם את/ה בטוח/ה שברצונך למחוק את המבצע?',
            confirmText: 'מחק',
            cancelText: 'ביטול',
            onConfirm: () => {
                DeleteSale(saleId)
                    .then(() => {
                        showAlert({ msgTitle: "המבצע נמחק בהצלחה", msgType: "success" });
                        setSalesList((prevSalesList) =>
                            prevSalesList.filter((item) => item.Id !== saleId)
                        );
                        setPartialSalesList((prevPartialSalesList) =>
                            prevPartialSalesList.filter((item) => item.Id !== saleId)
                        );
                    })
                    .catch(() => {
                        showAlert({ msgTitle: "הייתה בעיה במחיקת המבצע", msgType: "error" });
                    });
            }
        });
    };

    const normalizeDate = (date) => {
        return new Date(date.setHours(0, 0, 0, 0));
    };

    return (
        <div className="show-sales">
            <h1 className='title-role'>ניהול מבצעים</h1>
            <div className='btn'><NavLink className="sale-item" to={"/sale-page"} state={{ saleData: null }}>הוספת מבצע חדש</NavLink></div>
                <div className='sale-item-list'>
                    {salesList.map(item =>
                    <div className="all-sale-list">
                        <img
                            loading="lazy"
                            className="deleteSale"
                            onClick={() => handleDeleteSale(item.Id)}
                            src={globalFileServer + 'iconcss/deleteLocationAndListI.svg'}
                            alt="deleteSale"
                        />
                        <NavLink
                            key={item.Id}
                            to={"/sale-page"}
                            state={{ saleData: item }}
                            className='sale-item'
                            style={{
                                backgroundImage: `url(${globalFileIcon + 'addressBg.svg'})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '100%',
                                backgroundColor: 'transparent',
                                width: '140px',
                                height: '130px',
                                margin: '10px',
                                position: 'relative',
                            }}
                        >
                            {item.Text}
                        </NavLink>
                    </div>
                    )}
                </div>    
            <h2 className='saleRole'>מבצעים בתוקף:</h2>
            <div className='sale-item-list'>
                {salesList
                    .filter(item => {
                        const today = new Date();
                        const fromDate = new Date(item.FromDate);
                        const toDate = new Date(item.ToDate);
                        return normalizeDate(fromDate) <= normalizeDate(today) && normalizeDate(toDate) >= normalizeDate(today);
                    })
                    .map(item =>
                        <NavLink
                            key={item.Id}
                            to={"/sale-page"}
                            state={{ saleData: item }}
                            className='sale-item'
                            style={{
                                backgroundImage: `url(${globalFileIcon + 'addressBg.svg'})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '100%',
                                backgroundColor: 'transparent',
                                width: '140px',
                                height: '130px',
                                margin: '10px'
                            }}
                        >
                            {item.Text}
                        </NavLink>
                )}
                </div>
                <h2 className='saleRole'>מבצעים ללא הגדרת תחנות :</h2>
                <div className='sale-item-list'>
                    {partialSalesList
                        .map(item =>
                            <div className="all-sale-list">
                                <img
                                    loading="lazy"
                                    className="deleteSale"
                                    onClick={() => handleDeleteSale(item.Id)}
                                    src={globalFileServer + 'iconcss/deleteLocationAndListI.svg'}
                                    alt="deleteSale"
                                />
                                <NavLink
                                    key={item.Id}
                                    to={"/sale-page"}
                                    state={{ saleData: item }}
                                    className='sale-item'
                                    style={{
                                        backgroundImage: `url(${globalFileIcon + 'addressBg.svg'})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: '100%',
                                        backgroundColor: 'transparent',
                                        width: '140px',
                                        height: '130px',
                                        margin: '10px'
                                    }}
                                >
                                    {item.Text}
                                </NavLink>
                            </div>
                        )}
                    </div>
        </div>
    )
}

export default SalesManagement
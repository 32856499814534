import * as actionType from './action'

const initialState = {
    shoppingList: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_SHOP_LIST:
            return {
                ...state,
                shoppingList: action.payload
            };
        case actionType.ADD_NEW_SHOP_LIST:
            {
                const shoppingList = [...state.shoppingList];
                shoppingList.push(action.payload);
                return {
                    ...state,
                    shoppingList
                };
            }
        case actionType.DELETE_SHOP_LIST:
            {
                const shoppingList = state.shoppingList.filter(
                    (list) => list.Id !== action.payload
                );
                return {
                    ...state,
                    shoppingList
                };
            }
            
        default:
            return state
    }
}
export default reducer;
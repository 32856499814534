import React, { useState } from 'react';
import { addNewList ,deleteList} from '../../../server/ShoppingList'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from "react-router-dom";
import userEvent from '@testing-library/user-event';
import { globalFileServer, globalFileIcon } from '../../../../axiosInstance';
import { addToCard } from '../../../server/ShoppingList'
import showAlert from '../../../server/ShowAlert';
import { showAlertWithConfirmCancel } from '../../../server/ShowAlert'
import { CollectSiteProductGet } from '../../../server/CollectSite';


const UserShoppingList = ({ name }) => {
    const dispatch = useDispatch();
    const stationId = useSelector(state => state.user.stationId)
    const shoppingList = useSelector(state => state.shoppingList.shoppingList);
    const [newItemName, setNewItemName] = useState("");

    const addNewItemToList = () => {
        if (newItemName === "") {
            showAlert({ msgTitle: "הכנס שם לרשימה", msgType: "success" })
            return
        }
        dispatch(addNewList(newItemName))
        setNewItemName("")
    }

    const handleDeleteList = (listId) => {
        showAlertWithConfirmCancel({
            msgTitle: 'האם את/ה בטוח/ה שברצונך למחוק את הרשימה?',
            confirmText: 'מחק',
            cancelText: 'ביטול',
            onConfirm: () => {
                dispatch(deleteList(listId));
            },
        });
    };
    const AddToCard = (id) => {
        addToCard(id, stationId)
            .then(x => {
                if (x.data) {
                    dispatch(CollectSiteProductGet())
                    showAlert({ msgTitle: "הרשימה התווספה לעגלת קניות", msgType: "success" })
                }
                else {
                    showAlert({ msgTitle: "הייתה בעיה בהוספת הרשימה לעגלת קניות", msgType: "error" })
                }
            }
            ).catch(() => showAlert());
    }

    return (
        <div className="shopping-list">
            {shoppingList ? <div>
                <p className='shopping-list-header'> <img  loading="lazy" src={globalFileIcon + 'listHeader.svg'}  alt="list" />
                    הרשימות של {name}</p>
                <div className='list'>
                    {shoppingList.map(item =>
                        <div className='list-data'
                        key={item.Id}
                            style={{
                                backgroundImage: `url(${globalFileIcon + 'itemBg.svg'})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '100%',
                                backgroundColor: 'transparent',
                                height: '100%',
                            }}
                        // onClick={() => setActiveAddress(item)}
                        >

                                  <img
                                    loading="lazy"
                                    className="delete"
                                    onClick={() => handleDeleteList(item.Id)}
                                    src={globalFileServer + 'iconcss/deleteLocationAndListI.svg'}
                                    alt="delete"
                                />
                            <NavLink className='list-name'  to={`/shopping-list-product/${item.Id}`}>
                                {item.Name}
                            </NavLink>
                            <button className='btn-add' onClick={() => AddToCard(item.Id)}>הוסף לסל קניות&gt;</button>
                        </div>
                    )}
                    <div className='add-item'
                        style={{
                            backgroundImage: `url(${globalFileIcon + 'addressBg.svg'})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100%',
                            backgroundColor: 'transparent',
                            width: '140px',
                            height: '130px',
                            margin: '10px'
                        }}
                    >
                        <input
                            placeholder='הכנס שם לרשימה חדשה'
                            type="text"
                            value={newItemName ? newItemName : ""}
                            onChange={(e) => setNewItemName(e.target.value)}
                            onBlur={(e) => setNewItemName(e.target.value)}
                        />
                        <button className='btn-add-item' onClick={addNewItemToList}>הוסף רשימה</button>
                    </div>
                </div>
            </div>
                : <div className='empty-list'>
                    <h1>אין רשימת קניות </h1>
                    <div className='add-item'
                        style={{
                            backgroundImage: `url(${globalFileIcon + 'addressBg.svg'})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100%',
                            backgroundColor: 'transparent',
                            height: '20%',
                            width: '150px'
                        }}
                    >
                        <input
                            placeholder='הכנס שם לרשימה חדשה'
                            type="text"
                            value={newItemName ? newItemName : ""}
                            onChange={(e) => setNewItemName(e.target.value)}
                            onBlur={(e) => setNewItemName(e.target.value)}
                        />
                        <button className='btn-add-item' onClick={addNewItemToList}>הוסף רשימה</button>
                    </div>
                </div>}
        </div>
    )
}

export default UserShoppingList;
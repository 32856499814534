import React, { useEffect, useState } from "react";
import { globalFileServer } from "../../../../axiosInstance";
import { orderPost } from "../../../server/Order";
import showAlert from "../../../server/ShowAlert";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "../../../server/CollectSite";
import PrivateArea from "../../privateArea/PrivateArea";
import PayPopup from "../shopCart/PayPopup";
import { GetStation } from "../../../server/Station";

const ShopCart = () => {
  const dispatch = useDispatch();
  const {
    user,
    orderTime,
    productsInCart,
    deliveryPrice,
    orderSum,
    totalSum,
    stationId,
  } = useSelector((state) => ({
    user: state.user.user,
    stationId: state.user.stationId,
    orderTime: state.productsInCart.orderTime,
    productsInCart: state.productsInCart.productsInCart,
    orderSum: state.productsInCart.orderSum,
    totalSum: state.productsInCart.totalSum,
    deliveryPrice: state.productsInCart.deliveryPrice,
    // MinPrice: state.productsInCart.station.MinPrice,
    // MinFreeDeleviry: state.productsInCart.station.MinFreeDeleviry,
  }));

  const navigate = useNavigate();
  const [toPayPopup, setToPayPopup] = useState(false);
  const [selectedAddres, setSelectedAddres] = useState(false);
  const [station, setStation] = useState(null);

  const [paymentOrder, setPaymentOrder] = useState(0);
  useEffect(() => {
    let PaymentOrg = parseFloat(totalSum) + parseFloat(orderSum);
    if (
      PaymentOrg < station?.MinFreeDeleviry ||
      (!station?.MinFreeDeleviry && station?.MinFreeDeleviry !== 0)
    ) {
      PaymentOrg += deliveryPrice;
    }
    setPaymentOrder(PaymentOrg);
  }, [totalSum, station, orderSum]);

  useEffect(() => {
    getStation();
  }, [stationId]);

  const splitPaymentsPay = (_succ, PaymentMataraTokenId, NumPayment) => {
    setToPayPopup(false);
    if (!_succ) {
      return;
    }
    const product_to_order = productsInCart.map((element) => ({
      ...element,
      Status: 1,
    }));
    if (paymentOrder - deliveryPrice <= station?.MinFreeDeleviry) {
      product_to_order.push({
        Price: deliveryPrice,
        ProductMkt: 999,
        Status: 1,
        Quantity: 1,
      });
    }
    const params = {
      CustomerId: user.Id,
      PaymentTranzilaTokenId: PaymentMataraTokenId,
      CustomerShippingAddressId: selectedAddres.Id,
      ExpectedDate: orderTime,
      NumPayment,
      PaymentOrg: paymentOrder.toFixed(2),
      Payment: 0,
      StationId: stationId,
      OrderSource: 2,
      product_to_order,
      CollectSiteOrderId: product_to_order[0].CollectSiteOrderId,
    };
    orderPost(params)
      .then(() => {
        dispatch(clearCart());
        showAlert({ msgTitle: "הזמנה נרשמה בהצלחה.", msgType: "success" });
        navigate("/");
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message;
        console.error("Error details:", error);
      showAlert({ msgTitle: `הזמנה לא נרשמה: ${errorMessage}`, msgType: "error" });
    });
  };

  const getStation = () => {
    GetStation(stationId).then((x) => setStation(x.data));
  };
  const toPay = () => {
    if (!orderTime) {
      error("אנא בחר זמן משוער להגעת המשלוח ", "error");
      return;
    }
    if (!selectedAddres) {
      error("אנא בחר כתובת למשלוח", "error");
      return;
    }
    const products = productsInCart.filter((element) => element.Quantity > 0);
    if (!products.length) {
      navigate("/");
    }
    if (products.length) {
      if (
        parseFloat(totalSum) + parseFloat(orderSum) >= station?.MinPrice ||
        station?.MinPrice == null
      ) {
        setToPayPopup(true);
      } else {
        error("מינימום הזמנה: " + station?.MinPrice + " ש״ח", "error");
      }
    }
  };

  const error = (textError, type) => {
    showAlert({ msgTitle: textError, msgType: type });
  };
  const handleChange = (nameChanged) => {
    //נרצה לשמור בuser את מה שהוא בחר
  };

  return (
    <div className="shop-cart">
      <PrivateArea parent="cart" setSelectedAddres={setSelectedAddres} />
      <span className="checkbox-and-btn">
        <div className="checkbox-style">
          <label>
            <input
              type="checkbox"
              checked={user.Verified}
              onChange={() => handleChange("Verified")}
            />
            <span className="verify">
              {" "}
              הנני מאשר/ת כי קראתי את{" "}
              <a target="_blank" href={globalFileServer + "policy_form.pdf"}>
                תקנון{" "}
              </a>
              אתר ׳המזון׳ ואת מדיניות הפרטיות, וברצוני להירשם בכפוף לתנאיהם.{" "}
            </span>
          </label>
          <label className="verify">
            <input
              type="checkbox"
              checked={user.VerifiedGetSms}
              onChange={() => handleChange("VerifiedGetSms")}
            />
            הנני מאשר/ת לקבל הטבות, ועדכונים בדוא״ל וב-SMS (ניתן להפסיק את קבלת
            העדכונים בכל עת).
          </label>
        </div>
        <button onClick={toPay} className="to-pay">
          סיום קנייה &gt;{" "}
        </button>
      </span>
      {toPayPopup ? (
        <PayPopup
          SumPay={Math.abs(paymentOrder)}
          tranmode={true}
          closePayPopup={(_succ, cardId, numPaymentSelect) =>
            splitPaymentsPay(_succ, cardId, numPaymentSelect)
          }
        />
      ) : null}
    </div>
  );
};

export default ShopCart;

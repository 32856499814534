import React, { useState, useEffect } from 'react';
import showAlert from '../../../server/ShowAlert';
import { Payment, SavePaymentNedarim, SavePaymen, GetMosadDetails } from '../../../server/Payment'
import { useParams } from 'react-router-dom';
import { GetPaymentPerOrder, GetCards } from '../../../server/Payment'
import PayNedarim from './PayNedarim';
import { useDispatch } from 'react-redux';
import { setPreload } from '../../../server/CartsOpen'
import SelectNumPay from './PayNum';
import PopUp from '../../containers/PopUp';
import { useSelector } from "react-redux";
import axios from "axios";
import { logDOM } from '@testing-library/react';


const PayPopup = ({ closePayPopup, tranmode, SumPay, orderId }) => {
	const user = useSelector((state) => state.user.user);
	const dispatch = useDispatch();
	const [sum, setSum] = useState(SumPay)
	const [massage, setMassage] = useState("המערכת מחפשת מידע מתאים");
	const [save, setSave] = useState(false);
	const [haveToAsk, setHaveToAsk] = useState(true);
	const [cardId, setCardId] = useState(null);
	const [Cards, setCards] = useState([]);
	const [ShowCards, setShowCards] = useState(true);
	const [numPaymentSelect, setNumPaymentsSelect] = useState(1);
	const [disabledClick, setDisabledClick] = useState(false)
	const [mosad, setMosad] = useState("");
    const [apiValid, setApiValid] = useState("");
    const [apiPassword, setApiPassword] = useState("");
	const { id } = useParams();

	useEffect(() => {
        GetMosadDetails()
            .then(response => {
                if (response.data && response.data.length >= 3) {
                    setMosad(response.data[0].split(": ")[1]);  
                    setApiValid(response.data[1].split(": ")[1]); 
                    setApiPassword(response.data[2].split(": ")[1]); 
                }
            })
            .catch(error => {
                console.error("Error fetching Mosad details:", error);
            });
    }, []);

	
	const CalcNumberPayments = (totalPrice) => {
		if (totalPrice >= 400) {
			return (Math.floor(totalPrice / 200) < 4) ? Math.floor(totalPrice / 200) : 4
		}
		return 1;
	}


	const pay = (orderId) => {
		setDisabledClick(true)
		if (tranmode) {
			const Card = Cards.find(x => x.Id == cardId);
		    handleDebitTokenPayment(Card.Token, sum, Card.Tokef);
			// closePayPopup(true, cardId, numPaymentSelect);
		}
		else {
			const params = {
				Id: cardId,
				Sum: sum,
				OrderId: orderId,
				numPay: numPaymentSelect
			}
			Payment(params)
				.then(() => {
					dispatch(setPreload(false));
					// closePayPopup(true);
				})
				.catch(() => {
					closePayPopup(false);
					showAlert({ msgTitle: 'העסקה נכשלה', msgText: 'אנא בדקו את פרטי הכרטיס או נסו כרטיס אחר', msgType: 'error' })
				});
		}

	}

	useEffect(() => {
		GetCards().then(x => setCards(x.data));
		if (id) {
			orderId = id.substring(5, id.length - 5);
			GetPaymentPerOrder(orderId)
				.then(x => {
					setSum(x.data.sum)
					setMassage("");
				})
				.catch(err => setMassage(err.Message));
		}
		else {
			setMassage("");
		}
	}, [])

	const total = Math.abs(parseFloat(sum).toFixed(2));
	const numPayments = CalcNumberPayments(total)
	const msg = " הסכום לתשלום הוא: "

	 const handleDebitTokenPayment = async (token, amount, tokef) => {
		// setIsPaymentInProgress(true);
		const roundedAmount = parseFloat(amount.toFixed(2));
		const paymentData = {
		  Mosad: mosad,          
		  Token: token,              
		  Tokef: tokef,            
		  Amount: roundedAmount,          
		  Tashloumim: numPaymentSelect,   
		  ClientName: `${user.Name} ${user.LastName}`,  
		  Mail: user.Email,          
		  Phone: user.Phone,         
		  Groupe: "",                
		  Avour: "",                 
		  Zeout: user.Id,                          
		  Currency: "1", 
		};
	
		const queryString = new URLSearchParams(paymentData).toString();
		const url = "https://matara.pro/nedarimplus/V6/Files/WebServices/DebitCard.aspx";
	
		try {
		  const response = await axios.post(url, queryString);
		  const responseData = response.data;
		//   setTransactionId(responseData.TransactionId);
		//   orderIdInNedarim = responseData.TransactionId;
		  	orderId = responseData.TransactionId;
		  const dataNedarin = {
			Response: 0,
			o_tranmode: null,
			expmonth: tokef.slice(0, 2) || null,
			CustomerId: user.Id || 0,
			myid: null,
			currency: parseInt(responseData.Currency) || 1,
			expyear: tokef.slice(-2) || null, 
			supplier: "hamazon",
			sum: parseFloat(responseData.Amount) || 0.0,
			benid: null,
			o_cred_type: null,
			lang: null,
			ccard: null,
			fail_url_address: null,
			cred_type: responseData.CompagnyCard || null,
			success_url_address: null,
			tranmode: null,
			ConfirmationCode: responseData.Confirmation || null,
			cardtype: responseData.Brand || null,
			cardissuer: null,
			cardaquirer: null,
			index: null,
			Tempref: null,
			TranzilaTK: token || null,
			ccno: responseData.LastNum || null,
			OrderId: parseInt(responseData.TransactionId) || 0,
			npay: parseInt(responseData.Tashloumim) || 0,
			spay: 0,
			fpay: parseFloat(responseData.FirstTashloum) || 0.0,
			HaveSave: save,
		  };
		// pay(responseData.TransactionId)
		  if (responseData.Status === "OK") {
			showAlert({
			  msgTitle: "העסקה הושלמה בהצלחה",
			  msgType: "success",
			});
			if(!cardId){
				await SavePaymentNedarim(dataNedarin);
			}
			await closePayPopup(true, cardId, numPaymentSelect);
			// await SavePaymen(dataNedarin)
		  } else {
			showAlert({
			  msgTitle: "שגיאה בתשלום",
			  msgText: responseData.Message || "לא נמסרה שגיאה",
			  msgType: "error",
			});
		  }
		} catch (error) {
		  showAlert({
			msgTitle: "שגיאה בשליחת הבקשה",
			msgText: error.message || "אירעה שגיאה בעת שליחת הבקשה",
			msgType: "error",
		  });
		} finally {
		//   setIsPaymentInProgress(false);
		//   ClosePayPopup(true, token, numPaymentSelect);
			// closePayPopup(true, token, numPaymentSelect);
		}
	  };

	return (
		<PopUp width='800px' close={() => closePayPopup(false)}>
			<div className="pay-popup">
				<div className='sum-to-pay'>{tranmode ? msg + total + " ₪" : null}</div>

				<p className='massage'>{massage}</p>

				{numPayments > 1 ? <p className='note'> שימו לב: לא ניתן לשלם תשלומים בכרטיס דיירקט!</p> : null}
				{ShowCards && Cards.length ?
					<div className='use-saved-card'>
						ניתן לבחור כרטיס  ששמור במערכת
						{Cards.map(x => <div key={x.Id}>
							<input
								type="checkbox"
								checked={cardId === x.Id}
								onChange={() => setCardId(x.Id)}
							/>
							{x.Name}
						</div>)}
						{cardId ?
							<div>
								{numPayments ? <SelectNumPay numPayment={numPaymentSelect} setNumPayment={setNumPaymentsSelect} numPayments={numPayments} /> : null}
								{!numPayments || numPaymentSelect ? <button className="btn" disabled={disabledClick} onClick={pay}>אישור תשלום </button> : null}
							</div>
							: null}
						<button className="btn" onClick={() => setShowCards(false)}> ברצוני להשתמש בכרטיס אחר</button>
					</div> :
					<span className='save-card'>
						{tranmode && numPayments ? <SelectNumPay
							disabled={!haveToAsk}
							numPayment={numPaymentSelect}
							setNumPayment={setNumPaymentsSelect}
							numPayments={numPayments} /> : null}
						<p >
							האם ברצונך לשמור את פרטי האשראי במערכת להזמנות הבאות?
						</p>
						<span className='save-card-checkbox'>
							<div className='checkbox-style'>
								<label> כן </label>
								<input
									type="checkbox"
									disabled={!haveToAsk}
									onChange={() => { setSave(true); setHaveToAsk(false) }}
								/>

							</div>
							<div className='checkbox-style'>
								<label> לא </label>
								<input
									type="checkbox"
									disabled={!haveToAsk}
									onChange={() => { setSave(false); setHaveToAsk(false) }}
								/>

							</div>
						</span>
						<br />
					</span>


				}
				{!haveToAsk ?
					<PayNedarim
						mosad={mosad} 
						apiValid={apiValid} 
						apiPassword={apiPassword} 
						numPayments={numPayments}
						numPaymentSelect={numPaymentSelect}
						orderIdInNedarim={orderId}
						total={total}
						save={save}
						tranmode={tranmode}
						ClosePayPopup={closePayPopup}
						pay={pay}
						handleDebitTokenPayment={handleDebitTokenPayment}
					/> : null}
			</div>
		</PopUp>

	);
}

export default PayPopup;
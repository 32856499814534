import React, { useEffect, useState } from 'react';
import { globalFileIcon } from '../../../../axiosInstance'
import Select from '../../containers/Select';
import { UpdateProduct, GetProductEditMini } from '../../../server/Product';
import showAlert from '../../../server/ShowAlert'
import { updateProductBox } from '../../../server/InventoryUpdate'
import { useDispatch, useSelector } from 'react-redux'
import { setPreload } from '../../../server/CartsOpen';




const ProductDetailsPopUp = ({ productMkt, CloseProductDetails }) => {
    const dispatch = useDispatch();
    const [product, setProduct] = useState({});
    const [ManufacturerPackageQuantity, setManufacturerPackageQuantity] = useState(0)
    const [BoxQuantity, setBoxQuantity] = useState(0);
    const [GrossWeight, setGrossWeight] = useState(0);

    const {p_box_type, stationId } = useSelector(state => ({
        p_box_type: state.parameters.p_box_type,
        stationId: state.user.stationId,
    }));
    useEffect(() => {
        GetProduct(productMkt)
    }, [])


    const SaveChangesProductDetailsPopUp = (product, BoxQuantityValue,GrossWeight, BoxTypeIdValue, ManufacturerPackageQuantityValue) => {
        dispatch(setPreload(true));
        product.BoxQuantity = BoxQuantityValue;
        product.BoxTypeId = BoxTypeIdValue;
        product.GrossWeight=GrossWeight;
        product.ManufacturerPackageQuantity = ManufacturerPackageQuantityValue;
        updateProductBox(product)
            .then(x => {
                if (x.data) {
                    showAlert({ msgTitle: "השינויים נשמרו בהצלחה", msgType: "success" })
                }
                else {
                    showAlert({ msgTitle: "השינויים לא נשמרו", msgType: "error" })
                }
            })
            .catch(() => showAlert())
            .finally(() => dispatch(setPreload(false)));
        CloseProductDetails()


    }

   
    const GetProduct = (mkt) => {
        GetProductEditMini(mkt)
            .then(x => {
                if (x.data) {
                    setProduct(x.data)
                    setBoxQuantity(x.data.BoxQuantity ? x.data.BoxQuantity : 0)
                    setGrossWeight(x.data.GrossWeight ? x.data.GrossWeight:0)
                    setManufacturerPackageQuantity(x.data.ManufacturerPackageQuantity ? x.data.ManufacturerPackageQuantity : 0)
                }

            })
            .catch(() => console.log("error"));
    }
    const HandleChangeBoxQuantity = (event) => {
        setBoxQuantity(event);
    }
    const HandleChangeGrossWeight = (event) => {
        setGrossWeight(event);
    }
    const HandleChangeManufacturerPackageQuantity = (event) => {
        setManufacturerPackageQuantity(event);
    }

    const UpdateProducts = (value, paramName) => {
        const productEdit = { ...product };
        productEdit[paramName] = value;

        let val = {
            Mkt: product.Mkt,
            Title: paramName,
            value: value,
            StationId: stationId
        };
        UpdateProduct(val)
            .then((x) => {
                if (x.data) {
                    setProduct(productEdit)
                }
                else {
                    showAlert({ msgTitle: "השינויים לא נשמרו", msgType: "error" })
                }
            })
            .catch(() => showAlert());
    }

    return (
        <div className="product-detail">
            <div className='close-btn' onClick={CloseProductDetails}>
                <img  loading="lazy" src={globalFileIcon + 'close-menu.svg'} alt="close-menu"  />
            </div>
            <div className='gross_Weight'>
                <p>משקל</p>
                <input
                    type="number"
                    value={GrossWeight}
                    onChange={(e) => HandleChangeGrossWeight(e.target.value)}
                />
            </div>           
            <div className='location-type'>
                <Select Title="סוג מיקום"
                    Options={p_box_type}
                    Value={product.BoxTypeId}
                    Name="BoxTypeId"
                    Mkt={product.Mkt}
                    SelectChosen={UpdateProducts}
                />
            </div> 
            <div className='quantity-in-box'>
                <p>כמות בארגז</p>
                <input
                    type="number"
                    value={BoxQuantity}
                    onChange={(e) => HandleChangeBoxQuantity(e.target.value)}
                />
            </div>
            <div className='quantity-in-manufacturer-box'>
                <p>כמות בארגז יצרן</p>
                <input
                    type="number"
                    value={ManufacturerPackageQuantity}
                    onChange={(e) => HandleChangeManufacturerPackageQuantity(e.target.value)}
                />
            </div>
            
            <div className='save-btn'>
                <button onClick={() => SaveChangesProductDetailsPopUp(
                    product,
                    BoxQuantity,
                    GrossWeight,
                    product.BoxTypeId,
                    ManufacturerPackageQuantity
                )}>שמור</button>
            </div>
        </div>
    )
}

export default ProductDetailsPopUp
import React, { Fragment, useEffect,useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { loginPost, ChangPassword } from '../../server/Customer';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Input from "../containers/InputForm";
import { validPassword } from '../../ValidFunction';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import PopUp from '../containers/PopUp';


const schema = yup.object({
 password: yup.string()
        .required('חובה להכניס סיסמה')
        .matches(/[a-zA-Z]/, "הסיסמא אינה יכולה להכיל אותיות בעברית")
        .matches(/[0-9.,-]/, "הסיסמה חייבת להכיל לפחות ספרה אחת או תו מיוחד")
        .min(8, "הסיסמה חייבת להיות לפחות 8 תווים")
        .max(15, "הסיסמה יכולה להיות עד 15 תווים בלבד"),

 confirmPass: yup.string().required("").oneOf([yup.ref('password'), null], 'סיסמה לא מתאימה'),
}).required();


const Reset = () => {
  const dispatch = useDispatch();
  const isValidToken = useSelector(state => state.user.isValidToken)
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const { token } = useParams()

  useEffect(() => {
    dispatch(loginPost(token));
  }, [])


  const restorePass = (data) => {
    data["Token"] = token;
    dispatch(ChangPassword(data));
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowConfirmPassVisibility = () => {
    setShowConfirmPass(!showConfirmPass);
  };
  const navigate = useNavigate()
  return (
    <PopUp width='500px' close={() => dispatch(navigate('/'))}>
      <div className="user-entry">
        <div className='user-entry-header reset'>
          <h3>איפוס סיסמה</h3>
        </div>

        {isValidToken ?
          <Fragment>
            <form className="login-form reset" onSubmit={handleSubmit(restorePass)}>

              <div className='input-container'> 
              <Input
                type={showPassword ? "text" : "password"}
                errors={errors}
                label="אנא הקלד סיסמה חדשה"
                name="password"
                register={register}
                className="password-input"
                toggleVisibility={togglePasswordVisibility}
                showPassword={showPassword}
                isPasswordField={true}
              />

              <Input
                type={showConfirmPass ? "text" : "password"}
                errors={errors}
                label="הקלד שוב"
                name="confirmPass"
                register={register}
                className="password-input"
                toggleVisibility={toggleShowConfirmPassVisibility}
                showPassword={showConfirmPass}
                isPasswordField={true}
              />
              </div>
              <button className='btn' type='submit'>עדכן סיסמה</button>
            </form>

          </Fragment>
          : <div>הקישור פג תוקף </div>}
      </div>

    </PopUp>

  )
}





export default Reset

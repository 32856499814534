import React, { useState, useEffect, useRef, Fragment, useCallback } from 'react';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import ProductPageWithImg from "../productPage/ProductPageWithImg";
import ProductLocationPopUp from '../productPage/ProductLocationPopUp';
import { globalFileServer } from '../../../../axiosInstance';
import { getFilters } from '../../../server/Filter';
import { getAllPerCategoryId } from '../../../server/Advertising';
import { getSales, getItemsPerCategoryDispatch, UpdateSort, getItemsPerWord, reloedFunc } from '../../../server/Product';
import { useDispatch, useSelector } from 'react-redux';
import PopUp from '../../containers/PopUp';
import Card from '../card/Card';
import { getAllCategory } from '../../../server/Category'
import CategoryHeaderPage from '../CategoryPageHeder';
import ProductAddToShoppingList from '../productPage/ProductAddToShoppingList';
import { setToggleSidebar } from "../../../server/CartsOpen";
import ProductFilters from "../categoryPage/ProductFilters"
import{showAlertWithConfirmCancel} from "../../../server/ShowAlert"


const CategoryPage = ({ allProduct, adminToCustomer, setCurrentSubCategory, currentSubCategory }) => {
    const navigate = useNavigate()
    const dragItem = useRef();
    const dragOverItem = useRef();
    const { id, FatherId, subId } = useParams();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const { categoriesParent, filterList, stationId, role, refresh,
        reloed,
        items, searchString, filterAndSort } = useSelector(state => ({
            categoriesParent: state.category.categories.filter(item => item.Id === parseInt(id)),
            filterList: state.displayProduct.filterList,
            items: state.displayProduct.itemsShow,
            reloed: state.displayProduct.reloed,
            searchString: state.displayProduct.searchString,
            refresh: state.productsInCart.refresh,
            role: state.user.role,
            stationId: state.user.stationId,
            filterAndSort: state.displayProduct.filterAndSort,
        }));
    const [itemsShow, setItemsShow] = useState(items)
    const [selectedProd, setSelectedProd] = useState([]);
    const [productPopUp, setProductPopUp] = useState(null);
    const [productLocationPopUp, setProductLocationPopUp] = useState(null);
    const [showValid, setShowValid] = useState("0");
    const [addToShopListPopUp, setAddToShopListPopUp] = useState(null);
    const [advertising, setAdvertising] = useState([]);
    const [countPage, setCountPage] = useState(30);
    const [pagePlace, setPagePlace] = useState(0);
    const [toggleFilter, setToggleFilter] = useState(false);
    const observerRef = useRef(null);

    useEffect(() => {
        observerRef.current = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const subCategoryId = entry.target.dataset.subcategoryId;
                        const parentCategoryId = entry.target.dataset.parentCategoryId;
                        if (subCategoryId && subCategoryId !== currentSubCategory) {
                            if(parentCategoryId === id)
                                setCurrentSubCategory(parseInt(subCategoryId, 10));
                            else{
                                setCurrentSubCategory(parseInt(parentCategoryId, 10));
                            }
                        }
                    }
                });
            },
            { threshold: 0.6 }
        );

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [currentSubCategory]);

    const observeSubCategories = useCallback(() => {
        const subCategoryElements = document.querySelectorAll('[data-subcategory-id]');
        subCategoryElements.forEach((el) => {
            if (observerRef.current) {
                observerRef.current.observe(el);
            }
        });
    }, []);

    const handleScroll = useCallback(() => {
        const bottom = (Math.ceil(window.innerHeight + window.scrollY) * 1.5) >= document.documentElement.scrollHeight;
        if (bottom) {
            setPagePlace(prevPagePlace => prevPagePlace + 1);
        }
        observeSubCategories();
    }, [observeSubCategories]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll])

    useEffect(() => {
        observeSubCategories();
    }, [itemsShow, observeSubCategories]);

    const getItems = () => {
        getAllPerCategoryId(id).then(x => setAdvertising(x.data))
        dispatch(reloedFunc())
        if (id) {
            dispatch(getItemsPerCategoryDispatch(id, stationId, adminToCustomer));
        }
        else if (pathname === "/search" && searchString) {
            dispatch(getItemsPerWord(searchString, stationId))
        }
        else if (pathname === "/sales") {
            dispatch(getSales(stationId));
        }
    }

    useEffect(() => {
        if (!filterList.length) {
            dispatch(getFilters())
        }
        if (!categoriesParent.length) {
            dispatch(getAllCategory());
        }
        getItems();
    }, [id, pathname, searchString, refresh])

    useEffect(() => {
        getItems();  
    }, [stationId]); 

    const matchOrder = (product, categoryId) =>{
        categoryId = parseInt(categoryId);
        if(product.CategoryId === categoryId)
            return [0, 0];
        if(product.FatherId === categoryId)
            return [product.CategoryOrderNumber, product.CategoryId];
        if(product.FatherFatherId === categoryId)
            return [product.CategoryFatherOrderNumber, product.FatherId];
        return [0, 0];
    }

    useEffect(() => {
        const showItem = allProduct ? items : items.filter(x => x.type === "advertising" ||
            showValid === "1" ||
            (showValid === "0" && x.DisableProductId === 1) ||
            (showValid === "-1" && x.DisableProductId > 1) ||
            (showValid === "2" && x.DisableProductId === 1 && x.QuantityCanOrder))
        if (advertising.length && items.length) {
            const itemsShowCopy = [...showItem];
            advertising.sort(() => Math.random() > Math.random());
            advertising.forEach(x => itemsShowCopy.splice(
                Math.floor(Math.random() * itemsShowCopy.length),
                0,
                { ...x, type: "advertising" }
            ))
            setItemsShow(itemsShowCopy)
        }
        const sortedProducts = showItem.sort((a, b) => {
            let [aOrder, aCompareId] = matchOrder(a, id);
            let [bOrder, bCompareId] = matchOrder(b, id);
            if(aCompareId !== bCompareId)
                return aOrder - bOrder;
            [aOrder, aCompareId] = matchOrder(a, aCompareId);
            [bOrder, bCompareId] = matchOrder(b, bCompareId);
            if(aCompareId !== bCompareId)
                return aOrder - bOrder;
            [aOrder, aCompareId] = matchOrder(a, aCompareId);
            [bOrder, bCompareId] = matchOrder(b, bCompareId);
            return aOrder - bOrder;
          });
        setItemsShow(sortedProducts);
        setPagePlace(0);
    }, [items, showValid, allProduct])

    const sortItems = async () => {
        let moveBefore = true;
        let categoryId = dragItem.current.CategoryId;
    
        if (dragItem.current.CategoryFullOrderNumber < dragOverItem.current.CategoryFullOrderNumber) {
            moveBefore = false;
            const foundItem = itemsShow
                .slice()  
                .reverse()
                .find(ele => ele.CategoryFullOrderNumber === dragOverItem.current.CategoryFullOrderNumber);
            
            if (foundItem) {
                dragOverItem.current.Mkt = foundItem.Mkt;
            } else {
                console.warn("Item not found in reversed list:", dragOverItem.current.CategoryFullOrderNumber);
            }
        }
    
        if (dragItem.current.CategoryFullOrderNumber > dragOverItem.current.CategoryFullOrderNumber) {
            const foundItem = itemsShow.find(ele => ele.CategoryFullOrderNumber === dragOverItem.current.CategoryFullOrderNumber);
            
            if (foundItem) {
                dragOverItem.current.Mkt = foundItem.Mkt;
            } else {
                console.warn("Item not found in list:", dragOverItem.current.CategoryFullOrderNumber);
            }
        }

        const data = {
            Mkt: dragOverItem.current.Mkt,
            MktChange: dragItem.current.Mkt,
            isBefore: moveBefore,
            CategoryId: categoryId,
        };
    
        showAlertWithConfirmCancel({
            msgTitle: 'הפעולה הזו תשנה את סדר הפריטים. האם ברצונך להמשיך?',
            confirmText: 'אישור',
            cancelText: 'ביטול',
            onConfirm: () => {
                try {
                    dispatch(UpdateSort(data, stationId, adminToCustomer));
                } catch (error) {
                    console.error('Error updating sort:', error);
                }
            },
        });
    };

    useEffect(() => {
        getItems();
    }, [adminToCustomer]);

    const find = itemsShow.find(x => x.CategoryFather1OrderNumber == id || x.CategoryId == id || x.CategoryFatherOrderNumber == id || x.FatherId == id || x.FatherFatherId == id);
    if (categoriesParent.length || pathname === "/search" || pathname === "/sales") {
        let CategoryName = "";
        let mainTitle;
        return (
            <div className="products-view">
                <CategoryHeaderPage id={id} FatherId={FatherId} subId={subId} />
                              
                {productPopUp ? <ProductPageWithImg selectedProd={selectedProd} close={() => setProductPopUp(!productPopUp)} /> : null}
                {addToShopListPopUp ? <PopUp width='500px' close={() => setAddToShopListPopUp(false)}><ProductAddToShoppingList selectedProd={selectedProd} close={() => setAddToShopListPopUp(false)} /></PopUp> : null}
                {productLocationPopUp ?
                    <PopUp width='900px' close={() => setProductLocationPopUp(!productLocationPopUp)}>
                        <ProductLocationPopUp product={selectedProd} /> <div onClick={() => setProductLocationPopUp(!productLocationPopUp)} className="overflow"></div></PopUp>
                    : null}
                {pathname === "/search" ? <h1 className="search-title">חיפוש מוצרים</h1> : null}
                {pathname === "/sales" ? <h1 className="search-title">מוצרי מבצע</h1> : null}

                <div className="flex-container">
                {filterAndSort ? <div className='side' >
						{/* <CategoryOrder /> */}
						{toggleFilter ? <div onClick={() => setToggleFilter(!toggleFilter)} className="fake-filter"></div> : null}
						<ProductFilters toggleFilters={toggleFilter} />
					</div> : null}
                    <div className="col-lg-9 category-card">
                        {reloed ? <h1>טוען מוצרים...</h1>
                         : find ||(searchString && itemsShow.length!=0) ? <>
                            {itemsShow.filter((_x, i) => i < countPage * (pagePlace + 1))
                                .map((element, index) => {
                                    let p = null;
                                    if (mainTitle !== element.CategoryName && (CategoryName !== element.CategoryName || !index)) {
                                        CategoryName = element.CategoryName;
                                        if (pathname !== "/sales" && pathname !== "/search") {
                                            p = <div 
                                                key={`category-${element.CategoryId}`} 
                                                className="category-name" >
                                                    <h1>{CategoryName}</h1>
                                                </div>;
                                        }
                                    }
                                    return (
                                        <Fragment key={index}>
                                            {p}
                                            {element.type === "advertising" ? 
                                                <div 
                                                data-subcategory-id={element.CategoryId}
                                                data-parent-category-id={element.FatherId}>
                                                    <div className="img" onClick={() => navigate(element.Url)}>
                                                        <img loading="lazy" src={`${globalFileServer}Advertising/${element.PathUrl}`} alt={element.CategoryName} />
                                                    </div>
                                                    {element.type}
                                                </div> 
                                            : 
                                                <div
                                                    className='col-lg-2'
                                                    data-subcategory-id={element.CategoryId}
                                                    data-parent-category-id={element.FatherId}
                                                    onDragStart={() => (dragItem.current = element)}
                                                    onDragEnter={() => (dragOverItem.current = element)}
                                                    onDragEnd={role ? sortItems : null}
                                                    draggable
                                                >
                                                    <Card
                                                        element={element}
                                                        productPopUp={() => { setProductPopUp(true); setSelectedProd(element); dispatch(setToggleSidebar(false)); }}
                                                        addToShopListPopUp={() => { setAddToShopListPopUp(true); setSelectedProd(element); }}
                                                        productLocationPopUp={() => { setProductLocationPopUp(true); setSelectedProd(element); dispatch(setToggleSidebar(false)); }}
                                                    />
                                                </div>
                                            }
                                        </Fragment>
                                    );
                                })}
                        </>
                        : <h1>אין מוצרים במלאי</h1>}
                    </div>
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default CategoryPage;
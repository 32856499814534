import React, { Fragment, useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { globalFileServer } from "../../../../axiosInstance";
import { setPreload } from "../../../server/CartsOpen";
import {
  AddProduct,
  getItemsPerCategory,
  UpdateProduct,
  UpdateSort,
} from "../../../server/Product";
import { useDispatch, useSelector } from "react-redux";

const ProductsEdit = () => {
  const { stationId } = useSelector((state) => ({
    stationId: state.user.stationId,
  }));
  const dispatch = useDispatch();
  let { id } = useParams();
  useEffect(() => {
    getItemsPerCategory(id, stationId)
      .then((x) => setProducts(x.data));
  }, [stationId]);

  const [masc, setMasc] = useState(false);
  const [search, setSearch] = useState("");
  const [product, setProducts] = useState([]);

  const addItem = () => {
    dispatch(AddProduct(id));
  };

  const sortItems = (element, count) => {
    let chosenIndex = 0;
    let prods = [...product];
    for (let i = 0; i < prods.length; i++) {
      if (prods[i].Mkt === element.Mkt) {
        chosenIndex = i;
      }
    }
    let nOrder = [];
    if (count > 0) {
      let i = chosenIndex + 1;
      for (; i <= chosenIndex + count; i++) {
        nOrder.push({
          Mkt: prods[i].Mkt,
          orderNumber: prods[i - 1].orderNumber,
        });
      }
      nOrder.push({
        Mkt: prods[chosenIndex].Mkt,
        orderNumber: prods[i - 1].orderNumber,
      });
    } else {
      let i = chosenIndex + count;
      for (; i < chosenIndex; i++) {
        nOrder.push({
          Mkt: prods[i].Mkt,
          orderNumber: prods[i + 1].orderNumber,
        });
      }
      nOrder.push({
        Mkt: prods[i].Mkt,
        orderNumber: prods[chosenIndex + count].orderNumber,
      });
    }
    const data = {};
    data.List = nOrder;
    dispatch(UpdateSort(data,stationId,null));
  };

  const updateItems = (value, Mkt, paramName) => {
    const products = [...product];
    products.find((x) => x.Mkt === Mkt)[paramName] = value;
    products.sort((a, b) => {
      return a.orderNumber - b.orderNumber;
    });
    setProducts(products);
    let val = {
      Mkt: Mkt,
      title: paramName,
      value: value,
      StationId: stationId,
    };
    UpdateProduct(val);
  };

  return (
    <div className="products-edit">
      <h1 className="title-role">ניהול מוצרים</h1>
      <div className="items-container">
        <div className="flex-container">
          <button className="add-product" onClick={addItem}>
            <img
              loading="lazy"
              src={globalFileServer + "icons/plus.svg"}
              alt="plus"
            />
            <span>הוסף מוצר</span>
          </button>
          <div className="search">
            <input
              onChange={({ target }) => setSearch(target.value)}
              value={search}
              type="text"
              placeholder="חיפוש..."
            />
            {search ? (
              <img
                loading="lazy"
                className="close"
                onClick={() => setSearch("")}
                src={globalFileServer + "icons/close.svg"}
                alt="close"
              />
            ) : (
              <img
                loading="lazy"
                src={globalFileServer + "icons/search.svg"}
                alt="search"
              />
            )}
          </div>
        </div>
        <div className="product-edit-table">
          <table>
            <tbody>
              <tr>
                <th></th>
                <th>סדר</th>
                <th>תמונה </th>
                <th>כותרת</th>
                <th>סטאטוס</th>
              </tr>
              {product.length
                ? product
                    .filter(
                      (x) => x.ProductName && x.ProductName.includes(search)
                    )
                    .map((element, index) => {
                      return (
                        <tr key={element.Mkt}>
                          <td className="enter-img">
                            <NavLink to={"/edit-product/" + element.Mkt}>
                              <img
                                loading="lazy"
                                src={globalFileServer + "icons/enter.svg"}
                                alt="enter"
                              />
                            </NavLink>
                          </td>
                          <td className="sort-item">
                            <ul>
                              {index ? (
                                <li onClick={() => sortItems(element, -1)}>
                                  <img
                                    loading="lazy"
                                    src={
                                      globalFileServer +
                                      "icons/up-arrow-bold.svg"
                                    }
                                    alt="up-arrow"
                                  />
                                </li>
                              ) : null}
                              {product.length !== index + 1 ? (
                                <li onClick={() => sortItems(element, 1)}>
                                  <img
                                    loading="lazy"
                                    src={
                                      globalFileServer +
                                      "icons/down-arrow-bold.svg"
                                    }
                                    alt="down-arrow-bold"
                                  />
                                </li>
                              ) : null}
                            </ul>
                          </td>
                          <td className="category-img">
                            <div
                              onMouseOver={() =>
                                masc !== element.Mkt
                                  ? setMasc(element.Mkt)
                                  : null
                              }
                              onMouseLeave={() => setMasc(false)}
                              className={
                                element.Img ? "img-load active" : "img-load"
                              }
                            >
                              {element.PathUrl ? (
                                <Fragment>
                                  {masc === element.Mkt ? (
                                    <div className="masc">
                                      <img
                                        loading="lazy"
                                        src={
                                          globalFileServer +
                                          "product/" +
                                          element.PathUrl.split(",")[0] +
                                          "?width=150&height=150"
                                        }
                                        alt={element.ProductName}
                                      />
                                    </div>
                                  ) : null}
                                  <img
                                    className="main-img"
                                    alt={element.ProductName}
                                    src={
                                      globalFileServer +
                                      "product/" +
                                      element.PathUrl.split(",")[0] +
                                      "?width=150&height=150"
                                    }
                                    onLoad={() => dispatch(setPreload(false))}
                                  />
                                </Fragment>
                              ) : (
                                <div className="placeholder">
                                  <img
                                    loading="lazy"
                                    src={globalFileServer + "icons/picture.svg"}
                                    alt="picture add"
                                  />
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="title">
                            <p>{element.ProductName}</p>
                            <div className="prod-det-div">
                              <span>{"מק״ט: " + element.Mkt}</span>
                              <span>{"ברקוד: " + element.Barcode}</span>
                              {/* <span>{"מיון: " + element.orderNumber}</span> */}
                            </div>
                            <div className="prod-det-div">
                              <span>
                                {"משקל: " +
                                  element.WeightQuantity +
                                  element.UnitMeasure}
                              </span>
                              <span>{"מחיר: " + element.Price}</span>
                            </div>
                          </td>
                          <td className="status">
                            {element.DisableProductId === 1 ? (
                              <div
                                onClick={(e) =>
                                  updateItems(
                                    5,
                                    element.Mkt,
                                    "DisableProductId"
                                  )
                                }
                                className="input"
                              >
                                <img
                                  loading="lazy"
                                  src={globalFileServer + "icons/done.svg"}
                                  alt="done"
                                />
                              </div>
                            ) : (
                              <div
                                onClick={(e) =>
                                  updateItems(
                                    1,
                                    element.Mkt,
                                    "DisableProductId"
                                  )
                                }
                                className="input active"
                              >
                                <img
                                  loading="lazy"
                                  src={
                                    globalFileServer + "icons/cross-bold.svg"
                                  }
                                  alt="cross"
                                />
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProductsEdit;

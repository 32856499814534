import React, { useEffect, useState } from 'react';
import { GetPriceList } from '../../../server/PriceList';
import { Container, Row, Col } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { GetSalePrice } from '../../../server/SalesManagement'; 

const ComparePricePopUp = ({ product, setComparePricePopUp }) => {  
  const [currSlide, setCurrSlide] = useState(1);  
  const [company, setCompany] = useState([]);
  const [productData, setProductData] = useState({});  
  const [keepProfitMargin, setKeepProfitMargin] = useState(false);
  const [promoStartDate, setPromoStartDate] = useState(null);
  const [promoEndDate, setPromoEndDate] = useState(null);
  const [salePrice, setSalePrice] = useState(null);

  const { stationId, selectedProduct } = useSelector(state => ({
    stationId: state.user.stationId,
    selectedProduct: state.selectedProduct.product
  }));
  
  if (!product && selectedProduct) {    
    product = selectedProduct;  
  }
  
  useEffect(() => {    
    getCompareData();   
    fetchSalePrice();
    return () => {      
      if (setComparePricePopUp) {        
        setComparePricePopUp();      
      }    
    };
  }, []);
    
  useEffect(() => {   
    if (productData["תוקף מבצע"]) {      
      try {        
        const [start, end] = productData["תוקף מבצע"].split(" - ");        
        setPromoStartDate(start ? new Date(start) : null);        
        setPromoEndDate(end ? new Date(end) : null);      
      } catch (e) {        
        console.log("Error parsing promotion dates");      
      }
    }
  }, [productData["תוקף מבצע"]]);
  
  const fetchSalePrice = async () => {
    if (!product) return;
    try {
      const salePriceData = await GetSalePrice(product.Mkt, stationId);
      if (salePriceData.data && salePriceData.data !== -1 && salePriceData.data !== product.Price) {
        setSalePrice(salePriceData.data);
      }
    } 
    catch (error) {
      console.log("Error fetching sale price", error);
    }
  };
  const getCompareData = () => {    
    GetPriceList(product.Mkt, product.Barcode, stationId)
      .then(x => {        
        setCompany(x.data.list);        
        setProductData(x.data.obj[0] || {});      
      }).catch(() => console.log("error"));
  };
  
  const handleInputChange = (field, value) => {    
    setProductData(prev => ({
      ...prev,
      [field]: value
    }));
  };
  
  const handleXYChange = (type, value) => {    
    const currentXY = productData["X יחידות ב-Y ש״ח"] || "";    
    const [x = "", y = ""] = currentXY.split(" ב-");        
    let newValue;    
    if (type === 'X') {      
      newValue = `${value} ב-${y.replace("ש\"ח", "").trim()}`;    
    } else {
      newValue = `${x} ב-${value}`;
    }        
    handleInputChange("X יחידות ב-Y ש״ח", newValue);
  };
  
  const parseXYValues = () => {    
    const xyString = productData["X יחידות ב-Y ש״ח"] || "";    
    if (!xyString || xyString === "—") 
      return { x: "", y: "" };
    const parts = xyString.split(" ב-");    
    const x = parts[0] || "";    
    const y = parts.length > 1 ? parts[1].replace("ש\"ח", "").trim() : "";       
    return { x, y };
  };
  
  const handleDateChange = (dateType, date) => {    
    if (dateType === 'start') {      
      setPromoStartDate(date);    
    } else {
      setPromoEndDate(date);
    }       
     
    const formattedStartDate = promoStartDate ? promoStartDate.toLocaleDateString('he-IL') : "";    
    const formattedEndDate = date ? date.toLocaleDateString('he-IL') : "";        
    
    if (dateType === 'start') {      
      handleInputChange("תוקף מבצע", `${date.toLocaleDateString('he-IL')} - ${formattedEndDate}`);    
    } else {      
      handleInputChange("תוקף מבצע", `${formattedStartDate} - ${date.toLocaleDateString('he-IL')}`);
    }  
  };
  
  const handleNumberInput = (field, value) => {    
    if (value === "" || !isNaN(value)) {      
      handleInputChange(field, value);
    }
  };
  
  const { x, y } = parseXYValues();
  const imagesArray = product?.PathUrl ? product.PathUrl.split(",") : [];  
  const slidesToShow = imagesArray.length > 0 ? Math.min(imagesArray.length, 3) : 1;
  const settings = {
    rtl: window.innerWidth > 1000,
    dots: false,
    arrows: window.innerWidth > 1000,
    infinite: imagesArray.length > 1,
    speed: 200,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    afterChange: (index) => setCurrSlide(index + 1)
  };

  return (
    <div className="product-page">
      <div className="product-wrapper-compare">
        <Container fluid>          
          <Row className="price-row">
            <Col sm={4} className="text-center">
              <div className="field-group">
                <h4 className="field-label">מחיר קנייה:</h4>
                <input 
                  type="number" 
                  step="0.01" 
                  value={productData["מחיר קניה"] || ""} 
                  onChange={(e) => handleNumberInput("מחיר קניה", e.target.value)} 
                  className="number-input" 
                />
              </div>
            </Col>
            <Col sm={4} className="text-center">
              <div className="field-group">
                <h4 className="field-label">מחיר מכירה:</h4>
                <input 
                  type="number" 
                  step="0.01" 
                  value={productData["מחיר מכירה"] || ""} 
                  onChange={(e) => handleNumberInput("מחיר מכירה", e.target.value)} 
                  className="number-input" 
                />
                <div className="checkbox-wrapper">
                  <input 
                    type="checkbox" 
                    checked={keepProfitMargin} 
                    onChange={() => setKeepProfitMargin(!keepProfitMargin)} 
                  />
                  <span>שמור על אחוז רווח</span>
                </div>
              </div>
            </Col>
            <Col sm={4} className="text-center">
              <div className="field-group">
                <h4 className="field-label">אחוז רווח:</h4>
                <input 
                  type="number" 
                  step="0.01" 
                  value={productData["אחוז רווח"] || ""} 
                  onChange={(e) => handleNumberInput("אחוז רווח", e.target.value)} 
                  className="number-input"
                />
              </div>
            </Col>
          </Row>
          
          <Row className="promo-row">
            <Col sm={4} className="text-center">
              <div className="field-group">
                <h4 className="field-label">מחיר מבצע:</h4>
                <input 
                  type="number" 
                  step="0.01" 
                  value={salePrice !== null ? salePrice : ""} 
                  onChange={(e) => handleNumberInput("מחיר מבצע", e.target.value)} 
                  className="number-input"
                />
              </div>
            </Col>
            <Col sm={8} className="text-center">
              <div className="field-group">
                <h4 className="field-label">תיאור מבצע:</h4>
                <textarea 
                  value={productData["תיאור מבצע"] || ""} 
                  onChange={(e) => handleInputChange("תיאור מבצע", e.target.value)} 
                  className="promo-description"
                />
              </div>
            </Col>
          </Row>

          <Row className="date-row">
            <Col sm={12} className="text-center">
              <div className="field-group">
                <h4 className="field-label">תוקף מבצע:</h4>
                <div className="date-range-container">
                  <div className="date-field">
                    <label>התחלה:</label>
                    <input 
                      type="date" 
                      name="salestartDate" 
                      onChange={(e) => handleDateChange('start', new Date(e.target.value))}
                      className="date-input"
                    />
                  </div>
                  <div className="date-field">
                    <label>סיום:</label>
                    <input 
                      type="date" 
                      name="saleEndDate" 
                      onChange={(e) => handleDateChange('end', new Date(e.target.value))}
                      className="date-input"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          
          <Row className="quantity-row">
            <Col sm={6} className="text-center">
              <div className="field-group">
                <h4 className="field-label">הקצאת כמות למבצע:</h4>
                <input 
                  type="number" 
                  value={productData["כמות מוקצית"] || ""} 
                  onChange={(e) => handleNumberInput("כמות מוקצית", e.target.value)} 
                  className="number-input"
                />
              </div>
            </Col>
            <Col sm={6} className="text-center">
              <div className="field-group">
                <h4 className="field-label">מינימום יחידות למחיר מבצע:</h4>
                <input 
                  type="number" 
                  value={productData["מינימום למבצע"] || ""} 
                  onChange={(e) => handleNumberInput("מינימום למבצע", e.target.value)} 
                  className="number-input"
                />
              </div>
            </Col>
          </Row>
          
          <Row className="limits-row">
            <Col sm={4} className="text-center">
              <div className="field-group">
                <h4 className="field-label">הגבלת יחידות למבצע:</h4>
                <input 
                  type="number" 
                  value={productData["הגבלת יחידות"] || ""} 
                  onChange={(e) => handleNumberInput("הגבלת יחידות", e.target.value)} 
                  className="number-input"
                />
              </div>
            </Col>
            <Col sm={4} className="text-center">
              <div className="field-group">
                <h4 className="field-label">הגבלת כמות לקוח לקנייה:</h4>
                <input 
                  type="number" 
                  value={productData["הגבלת כמות לקנייה"] || ""} 
                  onChange={(e) => handleNumberInput("הגבלת כמות לקנייה", e.target.value)} 
                  className="number-input"
                />
              </div>
            </Col>
            <Col sm={4} className="text-center">
              <div className="field-group">
                <h4 className="field-label">הגבלת כמות במבצע ללקוח:</h4>
                <input 
                  type="number" 
                  value={productData["הגבלת כמות בתקופת מבצע"] || ""} 
                  onChange={(e) => handleNumberInput("הגבלת כמות בתקופת מבצע", e.target.value)} 
                  className="number-input"
                />
              </div>
            </Col>
          </Row>
          
          <Row className="xy-row">
            <Col sm={4}>
            <div className="field-group xy-container">
              <input 
                type="number" 
                value={x} 
                onChange={(e) => handleXYChange('X', e.target.value)} 
                className="xy-input" 
                placeholder="מספר יחידות"
              />
              <span>יחידות ב-</span>
              <input 
                type="number" 
                step="0.01" 
                value={y} 
                onChange={(e) => handleXYChange('Y', e.target.value)} 
                className="xy-input" 
                placeholder="מחיר"
              />
              <span>ש"ח</span>
            </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ComparePricePopUp;
export const BaseUrl = "https://wstest.hamazon.co.il/";
export const S3Url = "https://d2hlhkfvlx0wnu.cloudfront.net";
export const newApiBaseUrl = "https://api.pgw.slssystems.net/v1"; // New backend URL

export const awsUrl = "https://api.hamazononline.com";

export const tranzilaMasof = "hamazont";

export const url = BaseUrl + "api/";
//export const url ="http://localhost:81/api/"
export const globalFileServer = S3Url + "/images/";
// export const globalFileServer ="https://img.hamazon.co.il/img/image/";
// export const globalFileServer ="https://cdn-img.hamazon.co.il/img/";
// export const globalFileServer2 =BaseUrl+"img/";
//"http://51.195.119.118/img/image/icons/about.svg"
export const globalFileIcon = globalFileServer + "iconcss/";
//BaseUrl +"img/iconcss/";
//""+"iconcss/";
export const sortCategoryId = 1127;
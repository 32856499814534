import axios from 'axios';
import { newApiBaseUrl } from '../../axiosInstance';


export const GetPriceList = async (gtin) => {
    try {
        const params = { gtin};

        const response = await axios.get(`${newApiBaseUrl}/productprices`, {
            params,
            headers: {
                // 'Authorization': `Bearer ${localStorage.token}`,
                'Content-Type': 'application/json'
            },
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching price list:", error);
        throw error;
    }
};

import React, { useState, useEffect } from "react";
import BasicRouter from './BasicRouter';
import UserEntry from './components/header/UserEntry';
import './App.scss';
import Nav from './components/nav-role/Nav';
import { loginPost, loginToken } from './server/Customer';
import { getAllCategory } from './server/Category'
import { CollectSiteProductGet } from './server/CollectSite';
import { setToggleCart } from './server/CartsOpen'
import { useSelector, useDispatch } from "react-redux";
import Header from "./components/header/Header";
import MiniCart from './components/miniCart/MiniCart';
import Footer from './components/footer/Footer';
import Preload from "./components/Preload";
import OpenText from "./components/OpenText";
import { customerList, customerListClear } from './server/ShoppingList'
import SubFooter from "./components/footer/SubFooter";
import { SystemMessagePopUp } from "./components/containers/PopUp";
import { useLocation } from 'react-router-dom'
import UserResetEmail from "./components/header/UserEmailReset";
import { ParameterName } from "./server/Filter";
import { GetShippingAddress } from "./server/ShippingAddress"
import PopAddress from "./components/routs/address/PopAddress"
import ProductPageWithImg from "./components/routs/productPage/ProductPageWithImg";


const App = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const { userEntry, user, preload, role, userHaveReset } = useSelector(state => ({
        user: state.user.user,
        role: state.user.role,
        userEntry: state.user.userEntry,
        preload: state.openCart.preload,
        userHaveReset: state.user.userHaveReset,
    }));

    const [canPay, setCanPay] = useState(true);
    const [startPopUp, setStartPopUp] = useState(true);
    const [currentSubCategory, setCurrentSubCategory] = useState(null);
    const [showStationPopUp, setShowStationPopUp] = useState(true);
    const [shippingAddress, setShippingAddress] = useState(null);
    const [adminToCustomer, setAdminToCustomer] = useState(null);
    const [allProduct, setAllProduct] = useState(true);

    useEffect(() => {
        getToken();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    useEffect(() => {
        dispatch(getAllCategory());
        if (!user) {
            dispatch(setToggleCart(false))
            dispatch(customerListClear())
        }
        else {
            getProducts();
            if (role) {
                dispatch(customerListClear())
                dispatch(ParameterName("stock_type"));
                dispatch(ParameterName("p_box_type"));
                dispatch(ParameterName("p_disable"));
                dispatch(ParameterName("p_provider"));
                dispatch(ParameterName("p_unit_measure"));
                dispatch(ParameterName("p_view_out_of_stock"));
                dispatch(ParameterName("status_location"));
                dispatch(ParameterName("location_cell_type"));
                dispatch(ParameterName("location_type"));
                dispatch(ParameterName("robot"));
                dispatch(ParameterName("p_manufacturer"));
                dispatch(ParameterName("p_unload_manufacturer_package"));
                dispatch(ParameterName("p_box_storge_way"));
                dispatch(ParameterName("p_storage_mode"));

            }
            else {
                dispatch(customerList());
            }
            if (user?.Id) {
                GetShippingAddress(user.Id)
                    .then(response => {
                        const mainAddress = response.data.find(address => address.MainAddress);
                        setShippingAddress(mainAddress || "כתובת לא נמצאה");
                    })
                    .catch(() => setShippingAddress("שגיאה בקבלת הכתובת"));
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const getToken = () => {
        if (!localStorage.token) {
            haveGetToken();
        }
        else {
            dispatch(loginPost(false))
        }
    }



    const haveGetToken = () => {
        const val = {
            'userName': "xxx",
            'password': "1258",
            "grant_type": "password"
        }
        dispatch(loginToken(val, false))
    }

    const getProducts = () => {
        if (user) {
            dispatch(CollectSiteProductGet())
        }
    }

    const [productPopUp, setProductPopUp] = useState(false);
    const [selectedProd, setSelectedProd] = useState(null);

    return (
        <div>
            {showStationPopUp && !role && user?.StationId ? (
                <PopAddress 
                    setShowStationPopUp={setShowStationPopUp}
                    shippingAddress={shippingAddress}>
                </PopAddress>
            ) : null}
            {startPopUp && !role && !showStationPopUp?
                <SystemMessagePopUp width='500px' close={() => setStartPopUp(false)}>
                    <OpenText ShowHome={false} />
                </SystemMessagePopUp> :
                null}
            <Header setAllProduct={setAllProduct} setAdminToCustomer={setAdminToCustomer} currentSubCategory={currentSubCategory}/>
            <Nav />
            {preload ? <Preload /> : null}
            <BasicRouter allProduct={allProduct} adminToCustomer={adminToCustomer} canPay={canPay} closePayPopup={() => setCanPay(false)} setCurrentSubCategory={setCurrentSubCategory} currentSubCategory={currentSubCategory}/>
            {!role ? <MiniCart setProductPopUp={setProductPopUp} setSelectedProd={setSelectedProd}/> : null}
            {productPopUp && <ProductPageWithImg selectedProd={selectedProd} close={() => setProductPopUp(false)} />}
            <SubFooter />
            <Footer />
            {userHaveReset ? <UserResetEmail Email={userHaveReset.Email} Password={userHaveReset.Password} customerId={userHaveReset.customerId} emailPassword={userHaveReset.passwordMail} /> : null}
            {!userHaveReset && userEntry[0] && !pathname.includes('reset/') ? <UserEntry typeOfUser={userEntry[1]} /> : null}
        </div>
    );
}

export default App;
import React, {useState} from 'react';


const ProductInfo = ({ selectedProd, setProductPopUp, setSelectedProd }) => {
    return (
        <>
            <h4 className='prod-name' onClick={()=>{setProductPopUp(true); setSelectedProd(selectedProd);}}>{selectedProd.ProductName}</h4>
            <h5>{selectedProd.Description}</h5>
            <div className="prod-info-quantity">
              {selectedProd.PackageQuantity ? (
                <h5>
                  <span>{selectedProd.PackageQuantity}</span>
                  {selectedProd.WeightQuantity ? (
                    <>
                      <span> יחי' * </span>
                      <span className="weight-quantity">
                        {selectedProd.WeightQuantity}
                      </span>
                      <span>{selectedProd.UnitMeasure}</span>
                    </>
                  ) : (
                    <span>{selectedProd.UnitMeasure}</span>
                  )}
                </h5>
              ) : null}
            </div>
            <div className="prod-info-line">

                <p className="prod-brand">{selectedProd.Brand}</p>
                <span className='line'>|</span>

                {selectedProd.PackageQuantity ?
                    <div>
                        <span className='prod-details'>{selectedProd.WeightQuantity }</span>
                        <span>{selectedProd.UnitMeasure}</span>
                    </div>
                    :
                    <div>
                        <span className='prod-details'>{selectedProd.WeightQuantity}</span>
                        <span>{selectedProd.UnitMeasure}</span>
                    </div>
                }
                {selectedProd.DairyName && ["חלבי", "בשרי", "פרווה"].includes(selectedProd.DairyName) ? (
                <div>
                    <span className='line'>|</span>
                    <span className={selectedProd.DairyId === 3 ? 'fleshy' : selectedProd.DairyId === 2 ? 'milky' : 'parve'}>{selectedProd.DairyName}</span>
                </div>
                ) : null}
                
            </div>
                    </>
    )
}
export default ProductInfo;
import React, { useState, useEffect } from 'react';
import { InventoryPerMkt } from '../../../server/InventoryUpdate';
import { useSelector } from 'react-redux';

const ProductLocationPopUp = ({ product }) => {
  const { stationId, selectedProduct, items, stockType } = useSelector((state) => ({
    stationId: state.user.stationId,
    selectedProduct: state.selectedProduct.product,
    items: state.displayProduct.itemsShow,
    stockType: state.parameters.stock_type,
  }));

  const [location, setLocation] = useState([]);
  const [hasReturn, setHasReturn] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [newLocation, setNewLocation] = useState('');
  const [newQuantity, setNewQuantity] = useState(1);

  if (!product && selectedProduct) {
    product = selectedProduct;

    const matchingProduct = items.find((item) => item.Mkt === selectedProduct.Mkt);
    if (matchingProduct) {
      product = matchingProduct;
    }
  }

  useEffect(() => {
    InventoryPerMkt(product.Mkt, stationId)
      .then((x) => {
        const data = x.data.filter((x) => x.StokeTypeId === 1);
        setLocation(data);
        setHasReturn(true);
      })
      .catch(() => console.log('error'));
  }, [product.Mkt, stationId]);

  const handleRowClick = (row) => {
    setSelectedRow(row);
    setNewQuantity(row.Quantity); // הגדר כמות ברירת מחדל
  };

  const handleLocationButtonClick = (locationName) => {
    if (!selectedRow) return;

    const updatedLocation = location.map((row) => {
      if (row.Id === selectedRow.Id) {
        return { ...row, Quantity: row.Quantity - newQuantity };
      }
      return row;
    });

    const newRow = {
      ...selectedRow,
      FullLocationName: locationName,
      Quantity: newQuantity,
      Id: Date.now(),
    };

    setLocation([...updatedLocation, newRow]);
    setSelectedRow(null);
    setNewLocation('');
    setNewQuantity(1);
  };

  const handleQuantityChange = (value) => {
    if (selectedRow) {
      const maxQuantity = selectedRow.Quantity;
      if (value >= 1 && value <= maxQuantity) {
        setNewQuantity(value);
      }
    }
  };

  return (
	<>
    <div className='product-location'>
      <div className='product-header'>
        <h3>
          מלאי כללי: {product.Quantity}. מלאי למכירה: {product.QuantityCanOrder} :זמין להזמנות: מוזמן: מוקפא: פגומים:{' '}
        </h3>
      </div>
      <div className='table-location'>
        <table>
          <tbody>
            <tr>
              <th>מיקום</th>
              <th>כמות</th>
              <th>כמות זמין</th>
              <th>תוקף</th>
              <th>מצב מלאי</th>
            </tr>
            {location.length ? (
              location.map((x) => (
                <tr
                  key={x.Id}
                  onClick={() => handleRowClick(x)}
                  style={{
                    backgroundColor: selectedRow?.Id === x.Id ? 'lightblue' : 'transparent',
                    cursor: 'pointer',
                  }}
                >
                  <td>{x.FullLocationName}</td>
                  <td>{x.Quantity}</td>
                  <td>{x.QuantityCanCollect}</td>
                  <td>{x.Expired ? new Date(x.Expired).toLocaleDateString('en-GB') : null}</td>
                  <td>{stockType.find((type) => type.Id === x.StokeTypeId)?.Name || 'לא נמצא'}</td>
                </tr>
              ))
            ) : hasReturn ? (
              <tr>
                <td>לא נמצא מיקומים במלאי</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
	{selectedRow && (
		<div className='add-row'>
            <div className='quantity-input'>
              <button onClick={() => handleQuantityChange(newQuantity - 1)}>-</button>
              <input
                type='number'
                value={newQuantity}
                onChange={(e) => handleQuantityChange(parseInt(e.target.value))}
              />
              <button onClick={() => handleQuantityChange(newQuantity + 1)}>+</button>
            
              <button className="btn" onClick={() => handleLocationButtonClick('פגומים')}>פגומים</button>
              <button className="btn" onClick={() => handleLocationButtonClick('איבודים')}>איבודים</button>
          </div>
		  </div>
        )}
	</>
  );
};

export default ProductLocationPopUp;
import SweetAlert from 'sweetalert2';
import Swal from 'sweetalert2';

const style = document.createElement('style');
    style.textContent = `.custom-confirm-button {
                            background-color: #d9534f !important; /* אדום */
                            color: white !important; /* טקסט לבן */
                            border: 2px solid #d9534f !important; /* מסגרת אדומה */
                        }
                        .custom-confirm-button:focus {
                            box-shadow: 0 0 5px #d9534f !important; /* הצללה אדומה */
                            outline: none !important; /* ביטול מסגרת ברירת מחדל */
                        }
                        .custom-confirm-button:hover {
                            background-color: #c9302c !important; /* אדום כהה יותר */
                        }
                        .custom-icon-class {
                            color: #ff6347;
                            border: 2px solid #ff6347;
                            border-radius: 50%;
                            padding: 10px;
                            background-color: red;
                        }`;
document.head.appendChild(style);

export const showAlertWithConfirmCancel = ({ msgTitle, msgText, html, confirmText, cancelText, onConfirm, onCancel }) => {
    SweetAlert.fire({
        title: msgTitle || 'האם את/ה בטוח/ה?',
        text: msgText || '',
        html: html || '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: confirmText || 'אישור',
        cancelButtonText: cancelText || 'ביטול',
        reverseButtons: true,
        customClass: {
            confirmButton: 'custom-confirm-button', 
        }
    }).then((result) => {
        if (result.isConfirmed) {
            if (onConfirm) onConfirm();
        } else if (result.dismiss === SweetAlert.DismissReason.cancel) {
            if (onCancel) onCancel();
        }
    });
};

export const showAlertWithConfirm = ({ msgHtml, confirmButton, confirmText, msgType}) => {
    Swal.fire({
        html: msgHtml ? msgHtml : '<div></div>',
        showConfirmButton: confirmButton ? confirmButton : true,
        confirmButtonText: confirmText ? confirmText :'עבור לתשלום',
        showCloseButton: false,
        icon: msgType ? msgType : 'info',
        customClass: {
            confirmButton: 'custom-confirm-button', 
            icon: 'custom-icon-class',
        }
    })
}

const showAlert = ({ msgTitle, msgText, msgType, msgTimer }) => {
    SweetAlert.fire({
        icon: msgType ? msgType : 'error',
        title: msgTitle ? msgTitle : 'שגיאה בחיבור לרשת',
        text: msgText !== null ? msgText : 'בבקשה נסה שוב מאוחר יותר',
        timer: msgTimer ? msgTimer : 5000,
        showConfirmButton: false,
        didOpen: () => {
            const iconElement = document.querySelector('.swal2-icon');
            if (iconElement) {
                iconElement.style.cursor = 'pointer';
                iconElement.addEventListener('click', () => {
                    SweetAlert.close(); 
                });
            }
        }
    });
};

export default showAlert;

export const showAlertHTML = (msgHtml) => {
    SweetAlert.fire({
        html: msgHtml,
        showConfirmButton: false,
        showCloseButton: true
    })
}

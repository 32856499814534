import React, { useState, Fragment, useEffect } from 'react';
import Slider from "react-slick";
import ProductAddToCart from "./ProductAddToCart";
import { globalFileServer } from '../../../../axiosInstance';
import ProductInfo from '../../productInfo/ProductInfo';
import { TimeDateMonthSmallYear } from '../../containers/Date';
import { GetPriceList } from '../../../server/PriceList';
import { useSelector } from 'react-redux';
import { GetSalePrice } from '../../../server/SalesManagement';


const ProductPage = ({ selectedProd, setImageModal }) => {
  const [currSlide, setCurrSlide] = useState(1);
  const [imgView, setImgView] = useState(selectedProd.PathUrl ? selectedProd.PathUrl.split(",")[0] : false);
  const [company, setCompany] = useState([]);
  const [salePrice, setSalePrice] = useState(null);

  const { stationId } = useSelector(state => ({
    stationId: state.user.stationId,
  }));

  useEffect(() => {
    getCompareData();
    fetchSalePrice();

  }, [stationId]);

  const getCompareData = () => {
    GetPriceList(selectedProd.Barcode)
    .then(data => {
      console.log("Fetched Price List:", data);
  
      let formattedData = Object.entries(data).flatMap(([retailer, retailerData]) =>
        Object.entries(retailerData.branches).flatMap(([branch, branchData]) =>
          branchData.prices.map(price => ({
            retailer,
            branch,
            retailerName: branchData.retailerName || retailer,
            branchName: branchData.branchName || branch,
            price: price.price,
            date: price.date,
            promotionDescription: price.promotionDescription,
          }))
        )
      );
  
      // Find all "שערי רווחה" entries
      let shaareiRevachaEntries = formattedData.filter(entry => entry.retailerName.includes("שערי רווחה"));
  
      // If exactly 2 "שערי רווחה" entries exist and they have the same price, merge them
      if (
        shaareiRevachaEntries.length === 2 &&
        shaareiRevachaEntries[0].price === shaareiRevachaEntries[1].price
      ) {
        // Keep the first entry, update the retailerName, and remove the second
        shaareiRevachaEntries[0].branchName = null;
  
        formattedData = formattedData.filter(entry => entry !== shaareiRevachaEntries[1]);
      }
  
      setCompany(formattedData);
    })
    .catch(error => console.error("Error fetching price list:", error));
  
  };


  const fetchSalePrice = async () => {
    if (!selectedProd) return;
    try {
      const salePriceData = await GetSalePrice(selectedProd.Mkt, stationId);
      if (salePriceData.data && salePriceData.data !== -1 && salePriceData.data !== selectedProd.Price) {
        setSalePrice(salePriceData.data);
      }
    }
    catch (error) {
      console.log("Error fetching sale price", error);
    }
  };
  const settings = {
    rtl: window.innerWidth > 1000 ? true : false,
    dots: false,
    arrows: window.innerWidth > 1000 ? true : false,
    infinite: window.innerWidth > 1000 ? true : false,
    speed: 200,
    slidesToShow: window.innerWidth > 1000 ? selectedProd.PathUrl && selectedProd.PathUrl.split(',').length < 3 ? selectedProd.PathUrl.split(',').length : 3 : 1,
    slidesToScroll: window.innerWidth > 1000 ? selectedProd.PathUrl && selectedProd.PathUrl.split(',').length < 3 ? selectedProd.PathUrl.split(',').length : 3 : 1,
    afterChange: (index) => setCurrSlide(index + 1)
  };

  /*if(company.length > 1 && company[0]["שם החברה"].includes("שערי רווחה") && company[1].includes("שערי רווחה") && company[1]["מחיר מכירה"] === company[0]["מחיר מכירה"])
    {
      const newCompany = company.shift();
      newCompany[0]["שם החברה"] = "שערי רווחה";
      setCompany(newCompany);
    }*/

  return (
    <div className="product-pop-up">
      <div className="product-img">
        <img loading="lazy" onClick={() => setImageModal(imgView)} src={selectedProd.PathUrl ? `${globalFileServer}products/${imgView}` : `${globalFileServer}/placeholder.jpg`} />
        <div onClick={() => setImageModal(imgView)} className="img" style={{ backgroundImage: selectedProd.PathUrl ? `url(${globalFileServer}products/${imgView})` : `url(${globalFileServer}/placeholder.jpg)` }} />
        <div className="images-cont flex-container">
          <div className="col-lg-12 images">
            {selectedProd.PathUrl && selectedProd.PathUrl.split(',').length > 1 ?
              <div className="images-wrapper">
                <Slider {...settings}>
                  {selectedProd.PathUrl.split(',').map((element, index) => {
                    let imgActive = !imgView && selectedProd.PathUrl.split(',')[0] === element;
                    return (
                      <div
                        key={index}
                        className={element === imgView || imgActive ? "item active" : "item"}
                        onClick={() => setImgView(element)}>
                        <img loading="lazy" src={globalFileServer + 'products/' + element} alt={element.ProductName} />
                      </div>
                    );
                  })}
                </Slider>
              </div> : null}
            {selectedProd.PathUrl && selectedProd.PathUrl.split(',').length > 1 ?
              <div className="counter">
                <span>{currSlide + '/' + selectedProd.PathUrl.split(',').length}</span>
              </div> : null}
          </div>
        </div>
        <div className="price-btn">
          <ProductAddToCart element={selectedProd} haveInfo={true} />
        </div>
      </div>

      <div className="product-details">
        <div className='prod-info' style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1 }}>
            <ProductInfo selectedProd={selectedProd} />
            {selectedProd.Description ?
              <div className="description">
                <p>{selectedProd.Description}</p>
              </div> : null}
            <div className='prod-info-quantity'>
              {selectedProd.PackageQuantity ?
                <span>
                  <span>{selectedProd.PackageQuantity}</span>
                  <span> יחי' של </span>
                  <span className='weight-quantity'>{selectedProd.WeightQuantity}</span>
                  <span>{selectedProd.UnitMeasure}</span>
                </span>
                : null}
            </div>
          </div>
          <div className='kashrut'>
            {selectedProd.Kashrut && selectedProd.KashrutId != "19" ?
              <img loading="lazy" src={globalFileServer + 'iconcss/' + selectedProd.KashrutId + '.svg'} alt="kashrut" /> : null}
          </div>
        </div>
        <div>
          {selectedProd.Expired && (
            <p>תוקף: {new Date(selectedProd.Expired).toLocaleDateString('en-GB')}</p>
          )}
          {(selectedProd.Barcode || selectedProd.Mkt) && (
            <p>
              {selectedProd.Barcode && `ברקוד: ${selectedProd.Barcode}`}
              {selectedProd.Barcode && selectedProd.Mkt && '  '}
              {selectedProd.Mkt && `מק״ט: ${selectedProd.Mkt}`}
            </p>
          )}
        </div>
        <div className="price-compare-table">
          <h1 className="table-title">השוואת מחירים</h1>
          <div className="table-container">
            <table>
              <tbody>
                <tr style={{ backgroundColor: "#white", fontWeight: "bold" }}>
                  <td>המזון</td>
                  <td style={{ textDecoration: salePrice ? "line-through" : "none" }}>
                    {(parseFloat(selectedProd.Price)).toFixed(2)} ₪
                  </td>
                  <td style={{ backgroundColor: "white", color: "#721c24", fontWeight: "bold" }}> {salePrice && salePrice.toFixed(2)} ₪</td>
                </tr>{console.log("company", company)}
                {company.map((store, index) => {
                  const previousRowColor = index % 2 === 0 ? "#e0e0e0" : "white";
                  return (
                    <Fragment key={index}>
                      <tr style={{ backgroundColor: previousRowColor }}>
                      <td>{store.branchName ? `${store.retailerName} - ${store.branchName}` : store.retailerName}</td>
                        <td style={
                          store.promotionDescription && /^\d+(\.\d{1,2})? ₪$/.test(store.promotionDescription)
                            ? { textDecoration: "line-through" }
                            : {}
                        }>
                          {parseFloat(store.price).toFixed(2)} ₪
                        </td>
                        {store.promotionDescription ?
                          <td>
                            <tr className="sale-row" style={{ backgroundColor: previousRowColor }}>
                              <td colSpan={3}>
                                {store.promotionDescription}
                              </td>
                            </tr>
                          </td> : <td></td>}
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
import React, { useRef } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import PopUp from "../containers/PopUp";
import Detailes from '../routs/card/cardDeailes';


const MyCropperComp = ({ save, cancel, src, srcPrev, setSrcPrev, cropped, setCropped ,element}) => {


    // const img = new Image();
    // img.onload = () => {
    // const img = new Image();
    // img.onload = (x) => {
    //     console.log(x)
    // };
    // img.crossOrigin = 'Anonymous';
    // img.src = src;

    const cropper = useRef(null);


    const _cropChange = () => {
        if (cropped) {
            setCropped(false);
        }
    }


    const _crop = () => {
        const src = cropper.current.cropper.getCroppedCanvas().toDataURL();
        setCropped(true);
        setSrcPrev(src);
    }

    const _onReady = () => {
        const cropperInstance = cropper.current?.cropper;
        if (cropperInstance) {
            const canvasData = cropperInstance.getCanvasData();
            cropperInstance.setCropBoxData({
                left: canvasData.left,
                top: canvasData.top,
                width: canvasData.width,
                height: canvasData.height,
            });
        }
    };

    
    return (
        src ? 
            <PopUp width="900px">
                <div className="flex-container" style={{ marginRight: "50px" }}>
                    <div className="col-lg-6 for-crop" >
                        <Cropper
                            src={src}
                            aspectRatio={NaN}
                            guides={false}
                            checkCrossOrigin={"Anonymous"}
                            ref={cropper}
                            crop={_cropChange}
                            ready={_onReady}
                        />
                    </div>
                    <div className="col-lg-3.4" style={{  marginRight: "100px" }}>
                        <div className="item-card role cropped">
                            <Detailes 
                                inCart={false} 
                                productPopUp={() => { }} 
                                element={element} 
                                srcPrev={srcPrev} 
                            />
                        </div>
                    </div>
                </div>
                <ul className="actions">
                    <li>
                        {cropped ?
                            <button onClick={save} type="button" className="button-green">שמור</button> :
                            <button onClick={_crop} type="button" className="button-green">גזור</button>
                        }
                        <button onClick={cancel} type="button" className="button-red">ביטול</button>
                    </li>
                </ul>
            </PopUp> : null)

}

export default MyCropperComp